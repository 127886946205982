import { JiraLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  CobrandingComponents,
} from '../../../../components/whitebox-cobranding/shared-cobranding';
import defaultImageLeft from '../../../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../../../images/cobranding/default/default_right.svg';

export const DefaultCobrandingBackground = backgroundWithSideImages(
  defaultImageLeft,
  defaultImageRight
);

const AdminExperimentForJira: CobrandingComponents = {
  background: DefaultCobrandingBackground,
  logo: { component: JiraLogo },
  footerProducts: 'Jira, Confluence, Trello',
};

export default AdminExperimentForJira;
