import { __extends } from "tslib";
import Hookable from '../../common/hookable';
import Queue from '../transport/queue';
import { CoreType } from '..';
import { hasKey, objectKeys } from '../../common/utils/object-utils';
import { allowedTagsMap, allowedMetricTypeMap, metricAttributeRequirementsMap } from './constants';
import { PayloadType } from './types';
var defaultSettings = {
    sampleRate: 1,
    maxBufferSize: 10,
    flushInterval: 1000,
};
/**
 * Used by MetalClient to send Metrics
 *
 * Can be instantiated seperatedly if you also provide an instance of MetalSender.
 * Includes the following public methods.
 *
 * @method increment
 * @method decrement
 * @method timing
 * @method gauge
 *
 * @see Sender
 * @see MetalClient
 */
var assertHasValue = function (metric) {
    if (metric.value === undefined) {
        throw new Error("Missing value for metric " + metric.name);
    }
    if (typeof metric.value !== 'number') {
        throw new Error("Value for metric " + metric.name + " is of type " + typeof metric.value + " and not a number");
    }
};
// Note: we are allowing values of 0 to be submitted
// Reference: https://github.com/brightcove/hot-shots/blob/master/lib/statsFunctions.js#L110
var valueOrDefault = function (metric, defaultValue) {
    return metric.value === undefined || typeof metric.value !== 'number' ? defaultValue : metric.value;
};
var MetricModel = /** @class */ (function (_super) {
    __extends(MetricModel, _super);
    function MetricModel(_a) {
        var sender = _a.sender, _b = _a.settings, settings = _b === void 0 ? defaultSettings : _b, hook = _a.hook, pageVisibility = _a.pageVisibility;
        var _this = _super.call(this, { hook: hook }) || this;
        _this.sampleRate = settings.sampleRate;
        _this.queue = new Queue(settings, sender.send.bind(sender));
        _this.pageVisibility = pageVisibility;
        return _this;
    }
    MetricModel.prototype.updateSettings = function (newSettings) {
        this.queue.updateSettings(newSettings);
    };
    MetricModel.prototype.increment = function (metric) {
        metric.value = valueOrDefault(metric, 1);
        this.queue.enqueue(this.constructMetric(metric, PayloadType.INCREMENT));
    };
    MetricModel.prototype.decrement = function (metric) {
        metric.value = valueOrDefault(metric, -1);
        this.queue.enqueue(this.constructMetric(metric, PayloadType.DECREMENT));
    };
    MetricModel.prototype.timing = function (metric) {
        assertHasValue(metric);
        this.queue.enqueue(this.constructMetric(metric, PayloadType.TIMING));
    };
    MetricModel.prototype.gauge = function (metric) {
        assertHasValue(metric);
        this.queue.enqueue(this.constructMetric(metric, PayloadType.GAUGE));
    };
    /**
     * Called by user directly as a manual entrypoint
     *
     * @param {UserMetric} metric The metadata required to send a configured metric
     */
    MetricModel.prototype.submit = function (metric) {
        if (!hasKey(allowedMetricTypeMap, metric.name)) {
            throw new Error('Invalid Metric provided to Metal');
        }
        var tags = [];
        objectKeys(allowedTagsMap).forEach(function (key) {
            if (hasKey(metric, key)) {
                var metricObj = metric;
                if (key === 'histogramBuckets') {
                    var isBucketFormatRegex = /^([\d+]_?)*\d+$/;
                    if ((metric.histogramBuckets && !isBucketFormatRegex.test(metric.histogramBuckets)) ||
                        !metric.histogramBuckets) {
                        throw new Error('Invalid Metric value: histogramBuckets');
                    }
                }
                tags.push(allowedTagsMap[key] + ":" + metricObj[key]);
            }
        });
        var required = metricAttributeRequirementsMap[metric.name].required;
        required.forEach(function (key) {
            if (!hasKey(metric, key)) {
                throw new Error("Required metric attribute " + key + " is missing");
            }
        });
        var name = metric.name, value = metric.value;
        var method = allowedMetricTypeMap[name];
        this[method]({
            name: name,
            value: value,
            tags: tags,
        });
    };
    MetricModel.prototype.constructMetric = function (_a, type) {
        var name = _a.name, value = _a.value, _b = _a.tags, tags = _b === void 0 ? [] : _b;
        var data = {
            type: type,
            name: name,
            tags: tags,
            value: value,
            sampleRate: this.sampleRate,
        };
        // hook call to MetalClient callbacks
        this.hook({
            type: CoreType.METRIC,
            data: data,
        });
        return data;
    };
    return MetricModel;
}(Hookable));
export default MetricModel;
export * from './types';
