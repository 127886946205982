var ErrorMetricName;
(function (ErrorMetricName) {
    /** Page level */
    ErrorMetricName["UNCAUGHT"] = "fe.error.uncaught";
    ErrorMetricName["REQUEST"] = "fe.error.request";
    ErrorMetricName["API"] = "fe.error.api";
    ErrorMetricName["GRAPHQL"] = "fe.error.graphql";
    ErrorMetricName["VALIDATION"] = "fe.error.validation";
    ErrorMetricName["INPUT"] = "fe.error.input";
    ErrorMetricName["UNHANDLED_PROMISE_REJECTION"] = "fe.error.unhandledrejection";
    /** Component level */
    ErrorMetricName["COMPONENT"] = "fe.error.component";
    ErrorMetricName["COMPONENT_BOUNDARY"] = "fe.error.component.boundary";
    ErrorMetricName["COMPONENT_GRAPHQL"] = "fe.error.component.graphql";
    ErrorMetricName["COMPONENT_API"] = "fe.error.component.api";
})(ErrorMetricName || (ErrorMetricName = {}));
var PerformanceMetricName;
(function (PerformanceMetricName) {
    /** Page level */
    PerformanceMetricName["APDEX"] = "fe.perf.apdex";
    PerformanceMetricName["SPEED_INDEX"] = "fe.perf.speedindex";
    PerformanceMetricName["TIME_TO_INTERACTIVE"] = "fe.perf.time_to_interactive";
    PerformanceMetricName["TIME_TO_INTERACTIVE_SLO"] = "fe.perf.time_to_interactive.slo";
    PerformanceMetricName["TIME_TO_FIRST_BYTE"] = "fe.perf.time_to_first_byte";
    PerformanceMetricName["FIRST_PAINT"] = "fe.perf.first_paint";
    PerformanceMetricName["FIRST_CONTENTFUL_PAINT"] = "fe.perf.first_contentful_paint";
    PerformanceMetricName["FIRST_MEANINGFUL_PAINT"] = "fe.perf.first_meaningful_paint";
    PerformanceMetricName["FIRST_MEANINGFUL_PAINT_SLO"] = "fe.perf.first_meaningful_paint.slo";
    PerformanceMetricName["FIRST_INPUT_DELAY"] = "fe.perf.first_input_delay";
    PerformanceMetricName["REQUEST_TIMING"] = "fe.perf.request_timing";
    PerformanceMetricName["REQUEST_TIMING_SLO"] = "fe.perf.request_timing.slo";
    PerformanceMetricName["RESOURCE_TIMING"] = "fe.perf.resource_timing";
    PerformanceMetricName["CACHE_HITS"] = "fe.perf.cache_hits";
    /** Component level */
    PerformanceMetricName["COMPONENT_PREVIEW"] = "fe.perf.component.time_to_preview";
    PerformanceMetricName["COMPONENT_INTERACTIVE"] = "fe.perf.component.time_to_interactive";
    PerformanceMetricName["COMPONENT_READY"] = "fe.perf.component.time_to_ready";
    PerformanceMetricName["COMPONENT_READY_SLO"] = "fe.perf.component.time_to_ready.slo";
    PerformanceMetricName["COMPONENT_REQUEST_TIMING"] = "fe.perf.component.request_timing";
})(PerformanceMetricName || (PerformanceMetricName = {}));
var UserInteractionMetricName;
(function (UserInteractionMetricName) {
    UserInteractionMetricName["TASK_SUCCESS"] = "fe.user.task.success";
    UserInteractionMetricName["TASK_FAILURE"] = "fe.user.task.failure";
    UserInteractionMetricName["TASK_DURATION"] = "fe.user.task.duration";
    UserInteractionMetricName["TASK_TIME_TO_FEEDBACK"] = "fe.user.task.time_to_feedback";
    UserInteractionMetricName["TASK_TIME_TO_FEEDBACK_SLO"] = "fe.user.task.time_to_feedback.slo";
    UserInteractionMetricName["TASK_TIME_TO_COMPLETE"] = "fe.user.task.time_to_complete";
    UserInteractionMetricName["TASK_TIME_TO_COMPLETE_SLO"] = "fe.user.task.time_to_complete.slo";
})(UserInteractionMetricName || (UserInteractionMetricName = {}));
export { ErrorMetricName, PerformanceMetricName, UserInteractionMetricName };
