import { __assign, __read, __spread } from "tslib";
import merge from 'lodash/merge';
import Sender from '../core/transport/sender';
import MetricModel from '../core/metric';
import ErrorModel from '../core/error';
import { ErrorsPlugin } from '../plugins';
import getUserAgentInfo from '../common/user-agent-info';
import PageVisibility from '../common/page-visibility';
import PageTracker from '../common/page-tracker';
import { CoreType } from '../core';
import { isType, objectValues } from '../common/utils/object-utils';
import config from './config';
import { validateProductInfo, getDefaultHooks } from './helpers';
var defaultSettings = {
    getPage: function () { return ''; },
    metrics: {
        flushInterval: 1000,
        maxBufferSize: 10,
        sampleRate: 1,
        bufferType: CoreType.METRIC,
    },
};
var MetalClient = /** @class */ (function () {
    function MetalClient(_a) {
        var _this = this;
        var productInfo = _a.productInfo, _b = _a.settings, settings = _b === void 0 ? defaultSettings : _b, _c = _a.plugins, plugins = _c === void 0 ? [] : _c, _d = _a.forceXHR, forceXHR = _d === void 0 ? false : _d;
        /**
         * Internal hooks being used by Metal and responding to certain MetalEvents
         */
        this.hooks = getDefaultHooks();
        validateProductInfo(productInfo);
        var mergedSettings = merge(defaultSettings, settings, {
            metrics: {
                meta: settings.meta,
            },
        });
        this.productInfo = productInfo;
        var version = productInfo.version, metalId = productInfo.metalId, env = productInfo.env;
        this.meta = __assign(__assign(__assign({}, settings.meta), { version: version,
            env: env }), getUserAgentInfo());
        this.sender = new Sender({
            url: config.api[env],
            metalId: metalId,
            meta: this.meta,
            synthetic: settings.synthetic,
            forceXHR: forceXHR,
        });
        this.pageVisibility = new PageVisibility();
        this.pageTracker = new PageTracker(mergedSettings.getPage);
        // create the unified hook
        var hook = this.onSend.bind(this);
        this.metric = new MetricModel({
            sender: this.sender,
            settings: mergedSettings.metrics,
            hook: hook,
            pageVisibility: this.pageVisibility,
        });
        this.error = new ErrorModel({ hook: hook });
        this.updateSettings = this.updateSettings.bind(this);
        /**
         * Plugins are installed if an array is passed to Metal,
         * else by default we instantiate the default set of plugins.
         */
        var defaultPlugins = [new ErrorsPlugin()];
        this.plugins = __spread(defaultPlugins, plugins);
        this.plugins.forEach(function (plugin) { return plugin.install(_this); });
    }
    /**
     * Merges an object of new settings with the current settings.
     * @param newSettings The new settings object
     */
    MetalClient.prototype.updateSettings = function (newSettings) {
        if (newSettings.meta) {
            this.meta = __assign(__assign({}, this.meta), newSettings.meta);
            this.sender.updateMeta(this.meta);
        }
        if (newSettings.metrics) {
            this.metric.updateSettings(newSettings.metrics);
        }
    };
    Object.defineProperty(MetalClient.prototype, "initialPage", {
        get: function () {
            return this.pageTracker.initialPage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MetalClient.prototype, "currentPage", {
        get: function () {
            return this.pageTracker.page;
        },
        enumerable: false,
        configurable: true
    });
    // public interface to add a hook
    MetalClient.prototype.addEventHook = function (type, callback) {
        if (!isType(CoreType, type)) {
            throw new Error("Invalid hook, must be a CoreType [" + objectValues(CoreType) + "]");
        }
        this.hooks[type].push(callback);
    };
    MetalClient.prototype.destroy = function () {
        // Destroy plugins
        this.plugins.forEach(function (plugin) { return plugin.destroy && plugin.destroy(); });
    };
    // internal hook
    MetalClient.prototype.onSend = function (_a) {
        var _this = this;
        var type = _a.type, data = _a.data;
        this.hooks[type].forEach(function (callback) { return callback(data, _this.meta); });
    };
    return MetalClient;
}());
export default MetalClient;
export * from './types';
export { envTypes } from './constants';
