var _a;
import { __assign, __extends } from "tslib";
import noop from 'lodash/noop';
import { PerformanceMetricName } from '../../../catalog';
import { CallbackProvider } from '../../../common';
import { sendMetricTimingCallback, getPage } from '../utils';
import { getFIDAsync, getTTIAsync, getTTFBAsync } from './helpers';
var defaultCollectors = (_a = {},
    _a[PerformanceMetricName.FIRST_INPUT_DELAY] = getFIDAsync,
    _a[PerformanceMetricName.TIME_TO_INTERACTIVE] = getTTIAsync,
    _a[PerformanceMetricName.TIME_TO_FIRST_BYTE] = getTTFBAsync,
    _a);
var InteractiveMetricsPlugin = /** @class */ (function (_super) {
    __extends(InteractiveMetricsPlugin, _super);
    function InteractiveMetricsPlugin(_a) {
        var _b = _a.callbacks, callbacks = _b === void 0 ? [] : _b, _c = _a.collectors, collectors = _c === void 0 ? {} : _c, _d = _a.histograms, histograms = _d === void 0 ? {} : _d;
        var _this = _super.call(this, { callbacks: callbacks }) || this;
        _this.histograms = {};
        _this.page = '';
        _this.supportedMetrics = [
            PerformanceMetricName.FIRST_INPUT_DELAY,
            PerformanceMetricName.TIME_TO_INTERACTIVE,
            PerformanceMetricName.TIME_TO_FIRST_BYTE,
        ];
        _this.collectors = __assign(__assign({}, defaultCollectors), collectors);
        _this.histograms = histograms;
        return _this;
    }
    InteractiveMetricsPlugin.prototype.getTimings = function () {
        var _this = this;
        this.supportedMetrics.forEach(function (name) {
            _this.collectors[name]()
                .then(function (value) {
                return _this.doCallbacks({
                    metric: {
                        name: name,
                        value: value,
                        page: _this.page,
                    },
                    histograms: _this.histograms,
                    shouldCheckActiveTab: name !== PerformanceMetricName.FIRST_INPUT_DELAY,
                });
            })
                .catch(noop);
        });
    };
    InteractiveMetricsPlugin.prototype.install = function (client) {
        this.page = getPage(client.pageTracker, true);
        this.callbacks.push(sendMetricTimingCallback(client));
        this.getTimings();
    };
    return InteractiveMetricsPlugin;
}(CallbackProvider));
export { InteractiveMetricsPlugin as default };
