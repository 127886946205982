/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { defaultBannerHeight } from './constants';
var bannerStyles = css({
  width: '100%',
  position: 'fixed'
});
var bannerContainerStyles = css({
  width: '100%',
  position: 'relative',
  zIndex: 3,
  flex: '1 0 auto',
  transition: 'height 0.25s ease-in-out'
});
var pageContentStyles = css({
  minWidth: 0,
  position: 'relative',
  zIndex: 1,
  flex: '1 1 auto'
});
var wrapperStyles = css({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  flexDirection: 'column'
});
var mainContainerStyles = css({
  display: 'flex',
  flex: '1 1 auto'
});
var navigationStyles = css({
  position: 'relative',
  zIndex: 2
});

/**
 * __Page__
 *
 * Used to build page layouts.
 *
 * Has built in support for positioning [banners](https://atlassian.design/components/banner/examples)
 * and the deprecated `@atlaskit/navigation`.
 *
 * - [Examples](https://atlaskit.atlassian.com/packages/design-system/page)
 */
var Page = function Page(_ref) {
  var _ref$isBannerOpen = _ref.isBannerOpen,
    isBannerOpen = _ref$isBannerOpen === void 0 ? false : _ref$isBannerOpen,
    _ref$bannerHeight = _ref.bannerHeight,
    bannerHeight = _ref$bannerHeight === void 0 ? defaultBannerHeight : _ref$bannerHeight,
    banner = _ref.banner,
    navigation = _ref.navigation,
    children = _ref.children,
    testId = _ref.testId;
  return jsx("div", {
    css: wrapperStyles,
    "data-testid": testId
  }, banner ? jsx("div", {
    css: bannerContainerStyles,
    style: {
      height: isBannerOpen ? "".concat(bannerHeight, "px") : '0'
    },
    "aria-hidden": !isBannerOpen,
    "data-testid": testId ? "".concat(testId, "--banner-container") : undefined
  }, jsx("div", {
    css: bannerStyles
  }, banner)) : null, jsx("div", {
    css: mainContainerStyles
  }, jsx("div", {
    css: navigationStyles
  }, navigation), jsx("div", {
    css: pageContentStyles
  }, children)));
};
export default Page;