import { __assign } from "tslib";
import { objectValuesToString } from '../../../common/utils/object-utils';
import { NetworkStatus } from './types';
import Network from './network';
var promisifiedBeacon = function (url, blob) {
    return new Promise(function (resolve, reject) {
        try {
            var status_1 = navigator.sendBeacon(url, blob);
            if (status_1) {
                resolve(status_1);
            }
            else {
                reject(status_1);
            }
        }
        catch (e) {
            console.warn('MetalSender: sendBeacon failure');
        }
    });
};
var promisifiedXHR = function (url, body) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'text/plain');
        xhr.send(body);
        xhr.onerror = reject;
        xhr.onload = resolve;
        xhr.ontimeout = resolve;
    });
};
/**
 * Responsible for sending metric, log.. etc payloads
 * Configured by MetalClient directly
 */
var Sender = /** @class */ (function () {
    function Sender(_a) {
        var url = _a.url, meta = _a.meta, metalId = _a.metalId, synthetic = _a.synthetic, _b = _a.forceXHR, forceXHR = _b === void 0 ? false : _b;
        /**
         * Time between network retries
         */
        this.retryInterval = 30000;
        /**
         * buffer to hold things that fail due to network connectivity
         */
        this.buffer = [];
        /**
         * Favor using XHR over Beacon API
         */
        this.forceXHR = false;
        this.url = url;
        this.meta = meta;
        this.metalId = metalId;
        this.forceXHR = forceXHR;
        this.network = new Network({ onOnlineCallback: this.restartSending.bind(this) });
        this.sendData = this.sendData.bind(this);
        this.synthetic = synthetic;
    }
    Sender.prototype.updateMeta = function (meta) {
        this.meta = __assign(__assign({}, this.meta), meta);
    };
    Sender.prototype.restartSending = function () {
        var _this = this;
        this.buffer.forEach(function (payload) { return _this.sendData(payload); });
        this.buffer = [];
    };
    Sender.prototype.sendBeacon = function (blob) {
        return promisifiedBeacon(this.url, blob);
    };
    Sender.prototype.sendXHR = function (type, data, retries) {
        var _this = this;
        if (retries === void 0) { retries = 1; }
        return promisifiedXHR(this.url, data).catch(function () {
            if (retries < 3) {
                var retryIn = retries * _this.retryInterval;
                console.warn("MetalSender: Receiver not responding, will retry in " + Math.floor(retryIn / 1000) + "s");
                setTimeout(function () { return _this.sendXHR(type, data, retries + 1); }, retryIn);
            }
            else {
                console.warn("MetalSender: Max retries reached, payload (" + type + ") failed to send");
            }
        });
    };
    Sender.prototype.sendData = function (_a) {
        var type = _a.type, data = _a.data;
        if (this.network.status === NetworkStatus.OFFLINE) {
            this.buffer.push({ type: type, data: data });
            return Promise.resolve(null);
        }
        var metalId = this.metalId;
        var meta = objectValuesToString(this.meta);
        var body = __assign({ type: type,
            meta: meta,
            metalId: metalId,
            data: data }, (this.synthetic !== undefined ? { synthetic: String(this.synthetic) } : {}));
        if (!navigator.sendBeacon || this.forceXHR) {
            return this.sendXHR(type, JSON.stringify(body));
        }
        var headers = {
            type: 'application/json',
        };
        var blob = new Blob([JSON.stringify(body)], headers);
        return this.sendBeacon(blob);
    };
    /**
     * This is the public facing API for the sender; although this function
     * is intended for internal consumption by the MetalCore Classes.
     *
     * @param buffer {T[]}    An array of payload body type
     * @param type   {T}      A type of MetalCore eg, 'log', 'metric', etc...
     * @param meta   {object} Any additional meta info for this payload (global meta info)
     */
    Sender.prototype.send = function (buffer, type) {
        if (buffer === void 0) { buffer = []; }
        if (buffer.length < 1)
            return;
        this.sendData({
            type: type,
            data: buffer,
        }).catch(function (e) {
            // Catch rejection or any potential exceptions surfaced from promise.
            // e.g. Safari AdBlock is blocking access to metal and incurs a
            // promise rejection in promisifiedBeacon
            console.warn('MetalSender: sendData failure:', e);
        });
    };
    return Sender;
}());
export default Sender;
