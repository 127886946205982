import { CoreType } from '../../core';
import { objectValues } from '../../common/utils/object-utils';
/**
 * DevTools plugin observes calls to any MetalCore hook
 * and reports its result.
 *
 * Useful for verifying local development behaviour of MetalClient.
 */
var DevToolsPlugin = /** @class */ (function () {
    function DevToolsPlugin() {
        /* eslint-disable no-console */
        this.printError = function (error) {
            console.group("METAL " + CoreType.ERROR);
            console.table(error);
            console.groupEnd();
        };
        /* eslint-enable no-console */
        /* eslint-disable no-console */
        this.printMetric = function (metric) {
            console.group("METAL " + CoreType.METRIC + " (" + metric.type + ")");
            console.table({
                name: metric.name,
                value: metric.value,
                tags: metric.tags ? metric.tags.toString() : null,
            });
            console.groupEnd();
        };
        /* eslint-enable no-console */
    }
    DevToolsPlugin.prototype.install = function (client) {
        var _a;
        // Listen to all events from MetalClient
        var typeMap = (_a = {},
            _a[CoreType.ERROR] = this.printError,
            _a[CoreType.METRIC] = this.printMetric,
            _a);
        objectValues(CoreType).forEach(function (type) {
            client.addEventHook(type, function (data) {
                if (typeMap[type]) {
                    typeMap[type](data);
                }
            });
        });
    };
    return DevToolsPlugin;
}());
export default DevToolsPlugin;
