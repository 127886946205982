import { RESET_APP_STATE, useAppDispatch } from '../../stores/redux.store';
import { useLocation } from 'react-router-dom';
import { buildInitialState, PreloadedAppState } from '../init-app-state';
import { useEffect } from 'react';
import hasNoInjectedServerState from '../has-no-injected-server-state';
import { fetchApi, ServerErrorException } from '../fetch-api';
import { captureErrorMessage } from '../analytics/error-reporting';
import { setServerError } from '../../actions/server-error-actions';
import { ServerError } from '../../types';
import { isSasStateEndpointEnabled } from './usePageState';
import { useMaybeHardRedirect } from './useNavigateSoftOrHard';

const SAS_FAST_FIVE_FETCH_HEADER = 'sas-fast-five-fetch';
const EXCLUDED_PATHS = {
  existingSasMilestone2StateRoutes: ['/login', '/signup/welcome', '/signup/verify-email/otp/'],
  previousReduxStore: ['/2step/enrollment/'],
} as const;

/**
 * This hook is used on each new page load to fetch the initial state of the app from the
 * server and refresh the full redux state with that data.
 */
export const useGlobalPageState = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const maybeHardRedirect = useMaybeHardRedirect();

  useEffect(() => {
    const fetchData = async () => {
      // For non-bifrost pages (i.e. injected state from sign-in-service), use the existing non-sas
      // behaviour. This could mean we may have old injected state which doesn't get
      // refreshed from here on page navigation, but that matches existing behaviour
      const hasInjectedServerState = !hasNoInjectedServerState();
      if (hasInjectedServerState) {
        return;
      }

      const alreadyFetchesStateSpecificToRoute =
        isSasStateEndpointEnabled() &&
        EXCLUDED_PATHS.existingSasMilestone2StateRoutes.some(path => location.pathname === path);
      const requiresStateFromPreviousReduxStore = EXCLUDED_PATHS.previousReduxStore.some(path =>
        location.pathname.startsWith(path)
      );
      if (requiresStateFromPreviousReduxStore || alreadyFetchesStateSpecificToRoute) {
        return;
      }

      dispatch({ type: 'SET_GLOBAL_STATE_LOADING', isLoading: true });

      try {
        const url = location.pathname + location.search;
        const data = await fetchPageState(url);

        if (isRedirect(data)) {
          maybeHardRedirect(data.redirectTo);
          return;
        }

        const fullState = await buildInitialState(data);
        dispatch({
          type: RESET_APP_STATE,
          data: fullState.initialStoreState,
        });
      } catch (error) {
        let errorType: ServerError = { type: 'internal_server_error' };
        if (error instanceof ServerErrorException) {
          errorType = error.errorType;
          captureErrorMessage(`Failed to load AppState in Bifrost: ${error.message}`);
        } else {
          captureErrorMessage(`Unexpected error while loading AppState in Bifrost: ${error}`);
        }
        dispatch(setServerError(errorType));
      }

      dispatch({ type: 'SET_GLOBAL_STATE_LOADING', isLoading: false });
    };

    fetchData();
    // re-fetch whenever we change page, i.e. the fixed route
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};

type ServerSideRedirect = { redirectTo: string };

const isRedirect = (
  data: PreloadedAppState | ServerSideRedirect | null
): data is ServerSideRedirect => Boolean(data && 'redirectTo' in data);

const fetchPageState = async (
  fullPageUrl: string
): Promise<PreloadedAppState | ServerSideRedirect | null> => {
  const headers = {
    [SAS_FAST_FIVE_FETCH_HEADER]: 'true',
  };
  const response = await fetchApi(fullPageUrl, headers);
  const data = await response.json();

  return data;
};
