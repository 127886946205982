export var getDocumentHiddenValues = function () {
    var doc = document;
    var hiddenInfo = {
        isHidden: false,
        hidden: 'undefined',
        visibilityChange: 'undefined',
    };
    if (typeof doc.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hiddenInfo.isHidden = doc.hidden;
        hiddenInfo.hidden = 'hidden';
        hiddenInfo.visibilityChange = 'visibilitychange';
    }
    else if (typeof doc.msHidden !== 'undefined') {
        hiddenInfo.isHidden = doc.msHidden;
        hiddenInfo.hidden = 'msHidden';
        hiddenInfo.visibilityChange = 'msvisibilitychange';
    }
    else if (typeof doc.webkitHidden !== 'undefined') {
        hiddenInfo.isHidden = doc.webkitHidden;
        hiddenInfo.hidden = 'webkitHidden';
        hiddenInfo.visibilityChange = 'webkitvisibilitychange';
    }
    return hiddenInfo;
};
