import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { catalog } from '@atlassiansox/metal-client';
import { BMInteractionMetrics, BMPageLoadMetrics, StorableBMEventsType } from '../types';
var metricNameMatrix = {
  pageLoad: {
    fmp: {
      gauge: catalog.performance.FIRST_MEANINGFUL_PAINT,
      slo: catalog.performance.FIRST_MEANINGFUL_PAINT_SLO
    },
    tti: {
      gauge: catalog.performance.TIME_TO_INTERACTIVE,
      slo: catalog.performance.TIME_TO_INTERACTIVE_SLO
    }
  },
  pageSegmentLoad: {
    fmp: {
      gauge: catalog.performance.FIRST_MEANINGFUL_PAINT,
      slo: catalog.performance.FIRST_MEANINGFUL_PAINT_SLO
    },
    tti: {
      gauge: catalog.performance.TIME_TO_INTERACTIVE,
      slo: catalog.performance.TIME_TO_INTERACTIVE_SLO
    }
  },
  interaction: {
    response: {
      gauge: catalog.userInteraction.TASK_TIME_TO_FEEDBACK,
      slo: catalog.userInteraction.TASK_TIME_TO_FEEDBACK_SLO
    },
    result: {
      gauge: catalog.userInteraction.TASK_TIME_TO_COMPLETE,
      slo: catalog.userInteraction.TASK_TIME_TO_COMPLETE_SLO
    }
  },
  custom: {
    gauge: catalog.userInteraction.TASK_DURATION,
    sloSuccess: catalog.userInteraction.TASK_SUCCESS,
    sloFailure: catalog.userInteraction.TASK_FAILURE
  }
};
var makePageLoadPayload = function makePageLoadPayload(payload, type, common) {
  var metricName = "metric:".concat(type);
  var metricNameSlo = "metric:".concat(type, ":slo");
  var histogramNameSlo = "metric:".concat(type, ":histogramBuckets");
  var metalPayload = _objectSpread(_objectSpread({}, common), {}, {
    name: metricNameMatrix.pageLoad[type].gauge,
    value: payload[metricName]
  });
  var slo = payload[metricNameSlo];
  var histogram = payload[histogramNameSlo];
  var payloads = [];
  if (histogram !== undefined) {
    var histogramEvent = _objectSpread(_objectSpread({}, metalPayload), {}, {
      histogramBuckets: histogram
    });
    payloads.push(histogramEvent);
  }
  if (slo !== undefined) {
    var sloEvent = _objectSpread(_objectSpread({}, metalPayload), {}, {
      name: metricNameMatrix.pageLoad[type].slo,
      value: 1,
      success: slo
    });
    payloads.push(sloEvent);
  }
  return payloads;
};
var makeInteractionPayload = function makeInteractionPayload(payload, type) {
  var metricName = "metric:".concat(type);
  var metricNameSlo = "metric:".concat(type, ":slo");
  var histogramNameSlo = "metric:".concat(type, ":histogramBuckets");
  var gaugeValue = metricName in payload && payload[metricName];
  if (!gaugeValue && typeof gaugeValue !== 'number') {
    return [];
  }
  var metalPayload = {
    name: metricNameMatrix.interaction[type].gauge,
    value: gaugeValue,
    task: payload['event:id'],
    page: payload['event:route'] || ''
  };
  var payloads = [];
  var histogram = payload[histogramNameSlo];
  if (histogram !== undefined) {
    var histogramEvent = _objectSpread(_objectSpread({}, metalPayload), {}, {
      histogramBuckets: histogram
    });
    payloads.push(histogramEvent);
  }
  if (metricNameSlo in payload) {
    var sloPayload = _objectSpread(_objectSpread({}, metalPayload), {}, {
      name: metricNameMatrix.interaction[type].slo,
      value: 1,
      success: !!payload[metricNameSlo]
    });
    payloads.push(sloPayload);
  }
  return payloads;
};
var makeCustomPayload = function makeCustomPayload(payload) {
  var gaugeValue = payload['metric:duration'];
  if (!gaugeValue && typeof gaugeValue !== 'number') {
    return [];
  }
  var metalPayload = {
    name: metricNameMatrix.custom.gauge,
    value: gaugeValue,
    task: payload['event:id'],
    page: payload['event:route'] || ''
  };
  var payloads = [];
  var histogram = payload['metric:duration:histogramBuckets'];
  if (histogram !== undefined) {
    var histogramEvent = _objectSpread(_objectSpread({}, metalPayload), {}, {
      histogramBuckets: histogram
    });
    payloads.push(histogramEvent);
  }
  if ('metric:duration:slo' in payload) {
    var sloPayload = _objectSpread(_objectSpread({}, metalPayload), {}, {
      name: payload['metric:duration:slo'] ? metricNameMatrix.custom.sloSuccess : metricNameMatrix.custom.sloFailure,
      value: 1
    });
    payloads.push(sloPayload);
  }
  return payloads;
};
export var metalDataProcessor = function metalDataProcessor(payload, useVisibleState) {
  if (payload['event:type'] === StorableBMEventsType.PAGE_LOAD || payload['event:type'] === StorableBMEventsType.PAGE_SEGMENT_LOAD) {
    // page segment reuses METAL page load events
    var prefix = payload['event:type'] === StorableBMEventsType.PAGE_SEGMENT_LOAD ? 'segment-' : '';
    var isActiveTab = useVisibleState ? payload['pageVisible:state'] === 'visible' : payload['pageVisible:value'];
    var common = {
      page: "".concat(prefix).concat(payload['event:id']),
      isInitial: payload['event:initial'],
      isActiveTab: isActiveTab
    };
    return [].concat(_toConsumableArray(makePageLoadPayload(payload, BMPageLoadMetrics.fmp, common)), _toConsumableArray(makePageLoadPayload(payload, BMPageLoadMetrics.tti, common)));
  }
  if (payload['event:type'] === StorableBMEventsType.INLINE_RESULT) {
    var payloads = [];
    if (payload['metric:response']) {
      payloads.push.apply(payloads, _toConsumableArray(makeInteractionPayload(payload, BMInteractionMetrics.response)));
    }
    payloads.push.apply(payloads, _toConsumableArray(makeInteractionPayload(payload, BMInteractionMetrics.result)));
    return payloads;
  }
  if (payload['event:type'] === StorableBMEventsType.CUSTOM) {
    return makeCustomPayload(payload);
  }
  return [];
};