import { __read, __spread } from "tslib";
import { TIMEOUT_THRESHOLD, BROWSER_METRICS_MAP } from '../constants';
import i18n from '../../../i18n';
/**
 * PerformanceEntry => Metric
 * @param entry {PerformanceEntry}
 */
var normaliseData = function (_a) {
    var name = _a.name, startTime = _a.startTime;
    return ({
        name: BROWSER_METRICS_MAP[name],
        value: startTime,
    });
};
var normalisePaintPerformanceEntries = function (list) { return __spread(list).map(normaliseData); };
// hang on to this guy;
var paintPerformanceObserverPromise;
/**
 * TODO REWORK to deal with non compatible browsers
 *
 * This function needs to be super safe to work properly.
 *
 * 1. There's a chance the paint entries already exist by the time the script runs;
 *    in this case -- just use the entries.
 * 2. If not, use the performance observer.
 * 3. To ensure multiple calls don't confuse things we only ever return the same
 *    Promise instance.
 */
var getPaintTimingsWithPerformanceObserverAsync = function () {
    if (!paintPerformanceObserverPromise) {
        var performanceEntries = void 0;
        try {
            performanceEntries = performance.getEntriesByType('paint');
        }
        catch (e) {
            performanceEntries = [];
        }
        if (performanceEntries.length) {
            paintPerformanceObserverPromise = Promise.resolve(normalisePaintPerformanceEntries(performanceEntries));
        }
        else {
            paintPerformanceObserverPromise = new Promise(function (resolve, reject) {
                var observer = new PerformanceObserver(function (list) {
                    var entries = list.getEntries();
                    var normalisedData = normalisePaintPerformanceEntries(entries);
                    // clean up observer
                    observer.disconnect();
                    // pass back data
                    resolve(normalisedData);
                });
                try {
                    observer.observe({ entryTypes: ['paint'] });
                }
                catch (e) {
                    reject(e);
                }
            });
        }
    }
    return paintPerformanceObserverPromise;
};
var getPaintTimingsAsync = function () {
    // this is best
    if ('PerformanceObserver' in window) {
        return getPaintTimingsWithPerformanceObserverAsync();
    }
    // else
    if ('performance' in window) {
        return new Promise(function (resolve) {
            setTimeout(function () {
                var performanceEntries = performance.getEntriesByType('paint');
                resolve(__spread(performanceEntries).map(normaliseData));
                // this ensures we never wait more than TIMEOUT_THRESHOLD
            }, TIMEOUT_THRESHOLD - performance.now());
        });
    }
    return Promise.reject(new Error(i18n.errors.plugins.paint.unsupported));
};
var getFirstNavTiming = function () {
    var _a = __read(performance.getEntriesByName('first-navigation'), 1), firstNavigationMark = _a[0];
    var firstNavTiming = firstNavigationMark && firstNavigationMark.startTime;
    // if no timing exists just use TIMEOUT_THRESHOLD
    return firstNavTiming || TIMEOUT_THRESHOLD;
};
export { getPaintTimingsAsync, getPaintTimingsWithPerformanceObserverAsync, getFirstNavTiming };
