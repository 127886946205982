import { PerformanceMetricName } from '../../catalog';
import i18n from '../../i18n';
import PaintMetricsPlugin from './paint-timings';
import InteractiveMetricsPlugin from './interactive-timings';
import ResourceMetricsPlugin from './resource-timings';
/**
 * Wrapper class to simplify api.
 */
var BrowserMetricsPlugin = /** @class */ (function () {
    function BrowserMetricsPlugin(_a) {
        var rootId = _a.rootId, _b = _a.callbacks, callbacks = _b === void 0 ? [] : _b, _c = _a.collectors, collectors = _c === void 0 ? {} : _c, _d = _a.histograms, histograms = _d === void 0 ? {} : _d, _e = _a.blacklist, blacklist = _e === void 0 ? [] : _e;
        if (!rootId) {
            throw new Error(i18n.errors.metrics.browser.rootId);
        }
        this.interactiveMetrics = new InteractiveMetricsPlugin({ callbacks: callbacks, collectors: collectors, histograms: histograms });
        this.paintMetrics = new PaintMetricsPlugin({ rootId: rootId, callbacks: callbacks, collectors: collectors, histograms: histograms });
        this.resourceMetrics = new ResourceMetricsPlugin({ callbacks: callbacks, blacklist: blacklist });
        this.plugins = [this.interactiveMetrics, this.paintMetrics, this.resourceMetrics];
    }
    BrowserMetricsPlugin.prototype.install = function (client) {
        this.plugins.forEach(function (plugin) { return plugin.install(client); });
    };
    BrowserMetricsPlugin.prototype.destroy = function () {
        this.plugins.forEach(function (plugin) { return plugin.destroy && plugin.destroy(); });
    };
    return BrowserMetricsPlugin;
}());
/** Everything here is a plugin and can be used as such */
export { BrowserMetricsPlugin as default, PaintMetricsPlugin, InteractiveMetricsPlugin, ResourceMetricsPlugin, };
