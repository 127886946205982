var _a;
import { PerformanceMetricName } from '../../catalog';
// remove observer after 5 seconds
var TIMEOUT_THRESHOLD = 5000;
var BrowserMetricName;
(function (BrowserMetricName) {
    BrowserMetricName["FIRST_INPUT_DELAY"] = "first-input-delay";
    BrowserMetricName["TIME_TO_INTERACTIVE"] = "time-to-interactive";
    BrowserMetricName["FIRST_CONTENTFUL_PAINT"] = "first-contentful-paint";
    BrowserMetricName["FIRST_MEANINGFUL_PAINT"] = "first-meaningful-paint";
    BrowserMetricName["FIRST_PAINT"] = "first-paint";
    BrowserMetricName["RESOURCE_TIMING"] = "resource-timing";
    BrowserMetricName["REQUEST_TIMING"] = "request-timing";
})(BrowserMetricName || (BrowserMetricName = {}));
/**
 * Maps User metric namespaces to StatsD Metric namespace
 */
var BROWSER_METRICS_MAP = (_a = {},
    _a[BrowserMetricName.FIRST_INPUT_DELAY] = PerformanceMetricName.FIRST_INPUT_DELAY,
    _a[BrowserMetricName.TIME_TO_INTERACTIVE] = PerformanceMetricName.TIME_TO_INTERACTIVE,
    _a[BrowserMetricName.FIRST_CONTENTFUL_PAINT] = PerformanceMetricName.FIRST_CONTENTFUL_PAINT,
    _a[BrowserMetricName.FIRST_PAINT] = PerformanceMetricName.FIRST_PAINT,
    _a[BrowserMetricName.FIRST_MEANINGFUL_PAINT] = PerformanceMetricName.FIRST_MEANINGFUL_PAINT,
    _a[BrowserMetricName.REQUEST_TIMING] = PerformanceMetricName.REQUEST_TIMING,
    _a[BrowserMetricName.RESOURCE_TIMING] = PerformanceMetricName.RESOURCE_TIMING,
    _a);
export { BrowserMetricName, BROWSER_METRICS_MAP, TIMEOUT_THRESHOLD };
