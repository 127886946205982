import { __read } from "tslib";
import * as ttiPolyfill from 'tti-polyfill';
import i18n from '../../../i18n';
// TODO: consider re-naming these from 'helpers' to 'collectors / gatherers' something more semantic.
var getTTIAsync = function () {
    return new Promise(function (resolve, reject) {
        if (!window.perfMetrics) {
            reject(new Error(i18n.errors.metalhead.notfound));
        }
        if (!Object.prototype.hasOwnProperty.call(window, 'PerformanceLongTaskTiming')) {
            reject(new Error(i18n.errors.plugins.interactive.longtask));
        }
        ttiPolyfill.getFirstConsistentlyInteractive({}).then(resolve);
    });
};
var getFIDAsync = function () {
    return new Promise(function (resolve, reject) {
        if (!window.perfMetrics) {
            reject(new Error(i18n.errors.metalhead.notfound));
        }
        // onFirstInputDelay's callback also passes delay in ms, and the evt.
        window.perfMetrics.onFirstInputDelay(resolve);
    });
};
var getTTFBAsync = function () {
    return new Promise(function (resolve, reject) {
        try {
            var _a = __read(window.performance.getEntriesByType('navigation'), 1), navigationTiming = _a[0];
            if (navigationTiming) {
                /**
                 * Navigation Timing Level 2
                 * https://www.w3.org/TR/navigation-timing-2/
                 * Not supported: IE, Safari
                 */
                resolve(navigationTiming.responseStart);
            }
            else {
                /**
                 * Navigation Timing
                 * https://www.w3.org/TR/navigation-timing/
                 */
                var _b = window.performance.timing, responseStart = _b.responseStart, navigationStart = _b.navigationStart;
                resolve(responseStart - navigationStart);
            }
        }
        catch (e) {
            // in rare case that navigation timing is not supported
            reject(e);
        }
    });
};
export { getTTIAsync, getFIDAsync, getTTFBAsync };
