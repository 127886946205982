"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUUID = void 0;
function getUUID() {
    if (typeof crypto !== 'undefined' &&
        typeof crypto.randomUUID === 'function') {
        return crypto.randomUUID();
    }
    let d = new Date().getTime();
    let d2 = (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
        0;
    const y = '89ab'[Math.floor(Math.random() * 4)];
    return `xxxxxxxx-xxxx-4xxx-${y}xxx-xxxxxxxxxxxx`.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        }
        else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
}
exports.getUUID = getUUID;
