import { Action, AppConfig } from '../types';

export type State = AppConfig;

export const defaultState: State = {
  googleAuthClientId: '596149463257-9oquqfivs9on8t8erq23c8qso6vk3cp1.apps.googleusercontent.com',
  isSamlEnabled: true,
  recaptchaEnable: true,
  recaptchaEnterpriseCheckboxKeySite: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  recaptchaEnterpriseInvisibleKeySite: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  recaptchaEnterpriseScoreKeySite: '6LcIg3QnAAAAAPmTkAv_ZanJY5dRDUJo-i5hr_aN',
  smsEnrollmentRecaptchaEnabled: true,
  smsEnrollmentRecaptchaEnterpriseInvisibleKeySite: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  auth0Config: {
    domain: 'auth-domain.dev.atlassian.com',
    clientId: 'RJH00Zs94cwksZuhhkM133mJvmBA0NoQ',
    callbackUrl: 'http://localhost:3000/login/callback',
    deferRules: {
      apple: {
        callbackUrl: 'https://id.dev.internal.atlassian.com/login/apple/callback',
        clientId: 'EtITLqX61hS0sxFzgMEwYYWHosfYHjgY',
      },
      microsoft: {
        callbackUrl: 'https://id.dev.internal.atlassian.com/login/microsoft/callback',
        clientId: '3LDSJPtWZnovKS1gMCnKmp9Hk3iXlVEZ',
      },
      slack: {
        callbackUrl: 'https://id.dev.internal.atlassian.com/login/slack/callback',
        clientId: 'zKYyRODwsh165L0h41wALWAeqVfqDs0i',
      },
    },
  },
  segmentIoKey: '',
  castlePublishableApiKey: '',
};

const appConfig = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default appConfig;
