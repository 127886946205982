import { __read, __spread } from "tslib";
var CallbackProvider = /** @class */ (function () {
    function CallbackProvider(_a) {
        var _b = _a.callbacks, callbacks = _b === void 0 ? [] : _b;
        this.callbacks = [];
        // ensure we create a new array
        this.callbacks = callbacks.map(function (callback) { return callback; });
    }
    CallbackProvider.prototype.doCallbacks = function () {
        var data = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            data[_i] = arguments[_i];
        }
        this.callbacks.forEach(function (cb) { return cb.apply(void 0, __spread(data)); });
    };
    return CallbackProvider;
}());
export default CallbackProvider;
export { CallbackProvider };
