import '@atlaskit/css-reset';
import { setGlobalTheme } from '@atlaskit/tokens';
import React, { Suspense, useLayoutEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Outlet as ReactRouterOutlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from 'react-router-dom';
import ScreenSizeListener from '../components/ScreenSizeListener';
import { useAppSelector } from '../stores/redux.store';
import { CobrandingProvider, getCurrentCobranding } from '../utilities/cobranding/cobranding';
import messages from './App.messages';
import FlagContainer from './FlagContainer';
import {
  AuthCallbackErrorPage,
  CancelAccountDeletionPage,
  ChangePasswordPage,
  ChooseMethodPage,
  ConfigureAppPage,
  ConfigurePhonePage,
  ContinueAsPage,
  CreateAccountConfirmationPage,
  DeletionRequestConfirmationPage,
  EmailConfirmationPage,
  ExpiredPasswordPage,
  InactiveAccountPage,
  LoginConsentNoticePage,
  LoginPage,
  LogoutPage,
  MfaAppsPage,
  MfaEnrollmentPage,
  MfaPromotePage,
  MigrateAccountConfirmationPage,
  MultiFactorPage,
  PendingDeletionPage,
  RecoveryPage,
  RegisterSecurityKeyPage,
  RemoveAccountPage,
  ResetPasswordPage,
  SaveRecoveryKeyPage,
  SecurityExpiredLinkPage,
  SecuritySuccessPage,
  SelectAccountPage,
  SignupPage,
  SocialLoginRecoveryPage,
  StepUpSsoPage,
  StepUpStartPage,
  VerifyEmailOtpPage,
  VerifyOrReverifyEmailErrorPage,
  VerifyOrReverifyEmailSentPage,
  WelcomePage,
  WelcomeSentPage,
} from './LazyPages';
import { getCurrentPerimeter } from '../utilities/env';
import MobileAppMetaTags from '../utilities/MobileAppMetaTags';
import { useGlobalPageState } from '../utilities/hooks/useGlobalPageState';
import { useDynamicConfigs } from '../utilities/feature-flags/dynamic-config';
import { FullScreenSpinnerWrapper, LoadingSpinner } from '../components/LoadingSpinner';
import ServerErrorInterceptor from './ServerErrorInterceptor';
import UnSupportedMobileBrowserInterceptor from './UnSupportedMobileBrowserInterceptor';
import { BrowserMetricsBoundary } from '../browser-metrics';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { reactRouterFutureFlags } from '../helpers/react-router';

export function App() {
  useLayoutEffect(() => {
    void setGlobalTheme({ colorMode: 'light' });
  }, []);

  useGlobalPageState();

  const { formatMessage } = useIntl();
  const analyticsClient = useAnalyticsClient();
  const defaultTitle = formatMessage(messages.headTitle);

  const microbranding = useAppSelector(state => state.microbranding);
  const cobranding = useAppSelector(state => state.cobranding);

  const dynamicConfigs = useDynamicConfigs();

  const isGlobalStateLoading = useAppSelector(state => state.globalStateLoading);

  if (isGlobalStateLoading) {
    return (
      <FullScreenSpinnerWrapper>
        <LoadingSpinner testId="global-state-loading-spinner" />
      </FullScreenSpinnerWrapper>
    );
  }

  return (
    <ErrorBoundary analyticsClient={analyticsClient}>
      <ServerErrorInterceptor>
        <UnSupportedMobileBrowserInterceptor>
          <BrowserMetricsBoundary>
            <Suspense fallback={<LoadingSpinner testId="suspense-loading-spinner" />}>
              <Helmet
                key="helmet"
                defaultTitle={defaultTitle}
                titleTemplate={`%s - ${defaultTitle}`}
              />
              <MobileAppMetaTags />
              <CobrandingProvider
                value={{
                  cobranding: getCurrentCobranding(cobranding, dynamicConfigs),
                  isMobileApp: microbranding.isMobileApp,
                }}>
                <ScreenSizeListener key="screenSizeListener">
                  {/* React Router elements will be rendered in the ReactRouterOutlet */}
                  <ReactRouterOutlet />
                  <FlagContainer />
                </ScreenSizeListener>
              </CobrandingProvider>
            </Suspense>
          </BrowserMetricsBoundary>
        </UnSupportedMobileBrowserInterceptor>
      </ServerErrorInterceptor>
    </ErrorBoundary>
  );
}

export const router = createBrowserRouter(
  [
    {
      element: <App />,
      children: [
        {
          path: '/2step',
          children: [
            { index: true, element: <MfaAppsPage /> },
            {
              path: 'enrollment',
              children: [
                { index: true, element: <MfaEnrollmentPage /> },
                { path: '1-get-app', element: <ChooseMethodPage /> },
                { path: '2-configure-app', element: <ConfigureAppPage /> },
                { path: '2-configure-phone', element: <ConfigurePhonePage /> },
                { path: '2-register-security-key', element: <RegisterSecurityKeyPage /> },
                { path: '3-connect-phone', element: <ConfigureAppPage isConnectPhone /> },
                { path: '4-save-recovery-key', element: <SaveRecoveryKeyPage /> },
              ],
            },
            {
              path: 'recover',
              children: [
                { index: true, element: <RecoveryPage /> },
                { path: 'expired', element: <MultiFactorPage isExpired /> },
              ],
            },
          ],
        },
        { path: '/error', element: <AuthCallbackErrorPage /> },
        {
          path: '/login',
          children: [
            { index: true, element: <LoginPage /> },
            { path: 'cancel-account-deletion', element: <CancelAccountDeletionPage /> },
            { path: 'changepassword/*', element: <ChangePasswordPage /> },
            { path: 'consent', element: <LoginConsentNoticePage /> },
            { path: 'continue-as', element: <ContinueAsPage /> },
            {
              path: 'deletion-request-confirmation',
              element: <DeletionRequestConfirmationPage />,
            },
            { path: 'inactive', element: <InactiveAccountPage /> },
            { path: 'mfa', element: <MultiFactorPage isExpired={false} /> },
            { path: 'pending-deletion', element: <PendingDeletionPage /> },
            { path: 'remove-account', element: <RemoveAccountPage /> },
            { path: 'resetexpiredpassword', element: <ExpiredPasswordPage /> },
            { path: 'resetpassword', element: <ResetPasswordPage /> },
            { path: 'select-account', element: <SelectAccountPage /> },
            { path: 'security-screen', element: <MfaPromotePage /> },
            { path: 'social/confirmation', element: <CreateAccountConfirmationPage /> },
          ],
        },
        { path: '/logout', element: <LogoutPage /> },
        { path: '/mf', element: <MultiFactorPage isExpired={false} /> },
        {
          path: '/security',
          children: [
            { index: true, element: <NavigateToLogin /> },
            { path: 'expiredlink', element: <SecurityExpiredLinkPage /> },
            {
              path: 'successfulreset',
              element: <SecuritySuccessPage successType="password-reset" />,
            },
            {
              path: 'successfulverification',
              element: <SecuritySuccessPage successType="verification" />,
            },
          ],
        },
        {
          path: '/signup',
          children: [
            { index: true, element: <SignupCommercial /> },
            {
              // KIRBY-7259: SIS redirects /signup/invite/* to /signup/welcome/*
              // This is a temporary fix to handle the redirect for SAS Fast5
              path: 'invite/*',
              element: null,
            },
            { path: 'migrate-confirmation', element: <MigrateAccountConfirmationPage /> },
            { path: 'welcome/*', element: <WelcomePage /> },
            { path: 'welcome/sent', element: <WelcomeSentPage /> },
            { path: 'verify-email/otp', element: <VerifyEmailOtpPage /> },
          ],
        },
        { path: '/social/recovery', element: <SocialLoginRecoveryPage /> },
        {
          path: '/step-up',
          children: [
            { path: 'start', element: <StepUpStartPage /> },
            { path: 'sso', element: <StepUpSsoPage /> },
          ],
        },
        {
          path: '/verify-email/*',
          children: [
            { path: 'change-email', element: <EmailConfirmationPage /> },
            { path: 'sent', element: <VerifyOrReverifyEmailSentPage /> },
            { path: '*', element: <VerifyOrReverifyEmailErrorPage /> },
          ],
        },
        { path: '*', element: <NavigateToLogin /> },
      ],
    },
  ],
  { future: reactRouterFutureFlags }
);

function SignupCommercial() {
  if (getCurrentPerimeter() === 'commercial') {
    return <SignupPage />;
  }
  return <NavigateToLogin />;
}

function NavigateToLogin() {
  const { search } = useLocation();
  return <Navigate to={{ pathname: '/login', search }} replace />;
}
