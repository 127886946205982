import './polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import Spinner from '@atlaskit/spinner';

import ErrorBoundary, { UnexpectedApplicationErrorPage } from './components/ErrorBoundary';

import {
  captureException,
  initClient as initErrorReportingClient,
  setUserId as setErrorReportingUserId,
} from './utilities/analytics/error-reporting';
import LocaleSwitcher from './components/LocaleSwitcher';
import {
  buildInitialState,
  createAnalyticsClient,
  startupAnalyticEvent,
  pushGlobals,
  initCastle,
  preloadAppState,
} from './utilities/init-app-state';
import AnalyticsClientProvider from './containers/AnalyticsClientProvider';
import { router } from './containers/App';
import { startBrowserMetrics } from './browser-metrics';
import { createAppStore } from './stores/redux.store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FeatureGateProvider } from './providers';
import { getFfsId } from './utilities/ffs-id';

// Render a spinner to remove the "failed to load scripts" error while loading the app
const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner size="large" />
  </div>
);

const preloadedState = preloadAppState();

buildInitialState(preloadedState)
  .then(async initialState => {
    const { initialStoreState, cspNonce } = initialState;

    initErrorReportingClient(initialStoreState.microbranding);

    const analyticsClient = createAnalyticsClient(initialState);

    setErrorReportingUserId(analyticsClient.getAnonymousId());

    startupAnalyticEvent(analyticsClient);

    startBrowserMetrics(analyticsClient);

    const ffsId = getFfsId();
    if (ffsId) {
      analyticsClient.setFfsId(ffsId);
    }

    pushGlobals(initialStoreState.appConfig, cspNonce);

    const store = createAppStore(initialStoreState);

    if (initialStoreState.appConfig) {
      initCastle(initialStoreState.appConfig);
    }

    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    });

    root.render(
      <Provider store={store}>
        <LocaleSwitcher>
          <ErrorBoundary analyticsClient={analyticsClient}>
            <QueryClientProvider client={queryClient}>
              <AnalyticsClientProvider>
                <FeatureGateProvider>
                  <RouterProvider router={router} />
                </FeatureGateProvider>
              </AnalyticsClientProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </LocaleSwitcher>
      </Provider>
    );
  })
  .catch(error => {
    captureException(error);

    const emptyStore = createAppStore({});
    root.render(
      <Provider store={emptyStore}>
        <LocaleSwitcher>
          <UnexpectedApplicationErrorPage />
        </LocaleSwitcher>
      </Provider>
    );
  });
