var _a, _b;
import { __assign, __read, __spread } from "tslib";
import { ErrorMetricName, PerformanceMetricName, UserInteractionMetricName } from '../../catalog';
import { PayloadType, } from './types';
export var allowedMetricTypeMap = (_a = {},
    _a[ErrorMetricName.UNCAUGHT] = PayloadType.INCREMENT,
    _a[ErrorMetricName.REQUEST] = PayloadType.INCREMENT,
    _a[ErrorMetricName.API] = PayloadType.INCREMENT,
    _a[ErrorMetricName.GRAPHQL] = PayloadType.INCREMENT,
    _a[ErrorMetricName.VALIDATION] = PayloadType.INCREMENT,
    _a[ErrorMetricName.INPUT] = PayloadType.INCREMENT,
    _a[ErrorMetricName.UNHANDLED_PROMISE_REJECTION] = PayloadType.INCREMENT,
    _a[ErrorMetricName.COMPONENT] = PayloadType.INCREMENT,
    _a[ErrorMetricName.COMPONENT_BOUNDARY] = PayloadType.INCREMENT,
    _a[ErrorMetricName.COMPONENT_GRAPHQL] = PayloadType.INCREMENT,
    _a[ErrorMetricName.COMPONENT_API] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.APDEX] = PayloadType.TIMING,
    _a[PerformanceMetricName.SPEED_INDEX] = PayloadType.TIMING,
    _a[PerformanceMetricName.TIME_TO_INTERACTIVE] = PayloadType.TIMING,
    _a[PerformanceMetricName.TIME_TO_INTERACTIVE_SLO] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.TIME_TO_FIRST_BYTE] = PayloadType.TIMING,
    _a[PerformanceMetricName.FIRST_PAINT] = PayloadType.TIMING,
    _a[PerformanceMetricName.FIRST_CONTENTFUL_PAINT] = PayloadType.TIMING,
    _a[PerformanceMetricName.FIRST_MEANINGFUL_PAINT] = PayloadType.TIMING,
    _a[PerformanceMetricName.FIRST_MEANINGFUL_PAINT_SLO] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.FIRST_INPUT_DELAY] = PayloadType.TIMING,
    _a[PerformanceMetricName.REQUEST_TIMING] = PayloadType.TIMING,
    _a[PerformanceMetricName.REQUEST_TIMING_SLO] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.RESOURCE_TIMING] = PayloadType.TIMING,
    _a[PerformanceMetricName.CACHE_HITS] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.COMPONENT_PREVIEW] = PayloadType.TIMING,
    _a[PerformanceMetricName.COMPONENT_INTERACTIVE] = PayloadType.TIMING,
    _a[PerformanceMetricName.COMPONENT_READY] = PayloadType.TIMING,
    _a[PerformanceMetricName.COMPONENT_READY_SLO] = PayloadType.INCREMENT,
    _a[PerformanceMetricName.COMPONENT_REQUEST_TIMING] = PayloadType.TIMING,
    _a[UserInteractionMetricName.TASK_SUCCESS] = PayloadType.INCREMENT,
    _a[UserInteractionMetricName.TASK_FAILURE] = PayloadType.INCREMENT,
    _a[UserInteractionMetricName.TASK_DURATION] = PayloadType.TIMING,
    _a[UserInteractionMetricName.TASK_TIME_TO_FEEDBACK] = PayloadType.TIMING,
    _a[UserInteractionMetricName.TASK_TIME_TO_FEEDBACK_SLO] = PayloadType.INCREMENT,
    _a[UserInteractionMetricName.TASK_TIME_TO_COMPLETE] = PayloadType.TIMING,
    _a[UserInteractionMetricName.TASK_TIME_TO_COMPLETE_SLO] = PayloadType.INCREMENT,
    _a);
export var allowedTagsMap = {
    component: 'component',
    isActiveTab: 'active_tab',
    isInitial: 'initial_load',
    isSSR: 'ssr',
    page: 'page',
    success: 'success',
    task: 'task',
    taskId: 'task_id',
    type: 'type',
    histogramBuckets: 'gsd_histogram',
};
var alwaysRequired = ['page'];
var alwaysOptional = ['isSSR', 'isActiveTab'];
var timersAlwaysOptional = ['histogramBuckets'];
var defaultRequirements = {
    required: __spread(alwaysRequired),
    optional: __spread(alwaysOptional),
};
var defaultPerfRequirements = {
    required: __spread(alwaysRequired),
    optional: __spread(alwaysOptional, timersAlwaysOptional),
};
var defaultSLORequirements = {
    required: __spread(alwaysRequired, ['success']),
    optional: __spread(alwaysOptional),
};
var defaultComponentRequirements = {
    required: __spread(alwaysRequired, ['component']),
    optional: __spread(alwaysOptional),
};
var defaultComponentPerfRequirements = {
    required: __spread(alwaysRequired, ['component']),
    optional: __spread(alwaysOptional, timersAlwaysOptional),
};
var defaultTaskRequirements = {
    required: __spread(alwaysRequired, ['task']),
    optional: __spread(alwaysOptional, ['taskId']),
};
var defaultTaskPerfRequirements = {
    required: __spread(alwaysRequired, ['task']),
    optional: __spread(alwaysOptional, timersAlwaysOptional, ['taskId']),
};
export var metricAttributeRequirementsMap = (_b = {},
    // Errors
    _b[ErrorMetricName.UNCAUGHT] = defaultRequirements,
    _b[ErrorMetricName.REQUEST] = defaultRequirements,
    _b[ErrorMetricName.API] = defaultRequirements,
    _b[ErrorMetricName.GRAPHQL] = defaultRequirements,
    _b[ErrorMetricName.VALIDATION] = defaultRequirements,
    _b[ErrorMetricName.INPUT] = defaultRequirements,
    _b[ErrorMetricName.UNHANDLED_PROMISE_REJECTION] = defaultRequirements,
    _b[ErrorMetricName.COMPONENT] = defaultComponentRequirements,
    _b[ErrorMetricName.COMPONENT_BOUNDARY] = defaultComponentRequirements,
    _b[ErrorMetricName.COMPONENT_GRAPHQL] = defaultComponentRequirements,
    _b[ErrorMetricName.COMPONENT_API] = defaultComponentRequirements,
    // Page Performance
    _b[PerformanceMetricName.APDEX] = __assign(__assign({}, defaultRequirements), { optional: __spread(alwaysOptional, timersAlwaysOptional, ['isInitial']) }),
    _b[PerformanceMetricName.SPEED_INDEX] = defaultPerfRequirements,
    _b[PerformanceMetricName.TIME_TO_INTERACTIVE] = defaultPerfRequirements,
    _b[PerformanceMetricName.TIME_TO_INTERACTIVE_SLO] = defaultSLORequirements,
    _b[PerformanceMetricName.TIME_TO_FIRST_BYTE] = defaultPerfRequirements,
    _b[PerformanceMetricName.FIRST_PAINT] = defaultPerfRequirements,
    _b[PerformanceMetricName.FIRST_CONTENTFUL_PAINT] = defaultPerfRequirements,
    _b[PerformanceMetricName.FIRST_MEANINGFUL_PAINT] = defaultPerfRequirements,
    _b[PerformanceMetricName.FIRST_MEANINGFUL_PAINT_SLO] = defaultSLORequirements,
    _b[PerformanceMetricName.FIRST_INPUT_DELAY] = defaultPerfRequirements,
    _b[PerformanceMetricName.REQUEST_TIMING] = __assign(__assign({}, defaultPerfRequirements), { optional: __spread(alwaysOptional, timersAlwaysOptional, ['type']) }),
    _b[PerformanceMetricName.REQUEST_TIMING_SLO] = {
        required: __spread(alwaysRequired, ['success']),
        optional: __spread(alwaysOptional, ['type']),
    },
    _b[PerformanceMetricName.RESOURCE_TIMING] = __assign(__assign({}, defaultPerfRequirements), { optional: __spread(alwaysOptional, timersAlwaysOptional, ['type']) }),
    _b[PerformanceMetricName.CACHE_HITS] = defaultRequirements,
    // Component Performance
    _b[PerformanceMetricName.COMPONENT_PREVIEW] = defaultComponentPerfRequirements,
    _b[PerformanceMetricName.COMPONENT_INTERACTIVE] = defaultComponentPerfRequirements,
    _b[PerformanceMetricName.COMPONENT_REQUEST_TIMING] = defaultComponentPerfRequirements,
    _b[PerformanceMetricName.COMPONENT_READY] = defaultComponentPerfRequirements,
    _b[PerformanceMetricName.COMPONENT_READY_SLO] = __assign(__assign({}, defaultRequirements), { required: __spread(alwaysRequired, ['component', 'success']) }),
    // User Interaction (Tasks)
    _b[UserInteractionMetricName.TASK_SUCCESS] = defaultTaskRequirements,
    _b[UserInteractionMetricName.TASK_FAILURE] = defaultTaskRequirements,
    _b[UserInteractionMetricName.TASK_DURATION] = defaultTaskPerfRequirements,
    _b[UserInteractionMetricName.TASK_TIME_TO_FEEDBACK] = defaultTaskPerfRequirements,
    _b[UserInteractionMetricName.TASK_TIME_TO_FEEDBACK_SLO] = __assign(__assign({}, defaultTaskRequirements), { required: __spread(alwaysRequired, ['task', 'success']) }),
    _b[UserInteractionMetricName.TASK_TIME_TO_COMPLETE] = defaultTaskPerfRequirements,
    _b[UserInteractionMetricName.TASK_TIME_TO_COMPLETE_SLO] = __assign(__assign({}, defaultTaskRequirements), { required: __spread(alwaysRequired, ['task', 'success']) }),
    _b);
