import { NetworkStatus } from './types';
var Network = /** @class */ (function () {
    function Network(_a) {
        var _this = this;
        var onOnlineCallback = _a.onOnlineCallback;
        this.networkStatus = NetworkStatus.ONLINE;
        window.addEventListener('offline', function () {
            _this.networkStatus = NetworkStatus.OFFLINE;
        });
        window.addEventListener('online', function () {
            _this.networkStatus = NetworkStatus.ONLINE;
            onOnlineCallback();
        });
    }
    Object.defineProperty(Network.prototype, "status", {
        get: function () {
            // if ('onLine' in navigator) {
            //   return navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
            // }
            return this.networkStatus;
        },
        enumerable: false,
        configurable: true
    });
    return Network;
}());
export default Network;
