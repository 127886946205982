import { BrowserMetricName, BROWSER_METRICS_MAP } from '../constants';
import { AJAX_INITIATOR_TYPES } from './constants';
/**
 * Converts a Performance Resource Timing to our own Metric Type
 */
var normalisePerformanceResourceTiming = function (_a) {
    var initiatorType = _a.initiatorType, duration = _a.duration;
    var name = AJAX_INITIATOR_TYPES.indexOf(initiatorType) !== -1
        ? BrowserMetricName.REQUEST_TIMING
        : BrowserMetricName.RESOURCE_TIMING;
    return {
        name: BROWSER_METRICS_MAP[name],
        value: duration,
        type: initiatorType,
    };
};
export { normalisePerformanceResourceTiming as default };
