"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StableID = void 0;
const CacheKey_1 = require("./CacheKey");
const Log_1 = require("./Log");
const StorageProvider_1 = require("./StorageProvider");
const UUID_1 = require("./UUID");
const PROMISE_MAP = {};
exports.StableID = {
    get: (sdkKey) => {
        if (PROMISE_MAP[sdkKey] == null) {
            let stableID = _loadFromStorage(sdkKey);
            if (stableID == null) {
                stableID = (0, UUID_1.getUUID)();
                _persistToStorage(stableID, sdkKey);
            }
            PROMISE_MAP[sdkKey] = stableID;
        }
        return PROMISE_MAP[sdkKey];
    },
    setOverride: (override, sdkKey) => {
        PROMISE_MAP[sdkKey] = override;
        _persistToStorage(override, sdkKey);
    },
};
function _getStableIDStorageKey(sdkKey) {
    return `statsig.stable_id.${(0, CacheKey_1._getStorageKey)(sdkKey)}`;
}
function _persistToStorage(stableID, sdkKey) {
    const storageKey = _getStableIDStorageKey(sdkKey);
    try {
        (0, StorageProvider_1._setObjectInStorage)(storageKey, stableID);
    }
    catch (e) {
        Log_1.Log.warn('Failed to save StableID');
    }
}
function _loadFromStorage(sdkKey) {
    const storageKey = _getStableIDStorageKey(sdkKey);
    return (0, StorageProvider_1._getObjectFromStorage)(storageKey);
}
