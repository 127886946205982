import { __read, __spread } from "tslib";
import { DEFAULT_REQUEST_BLACKLIST } from './constants';
/**
 * Used to filter origins that should not be recorded by metal
 */
var getBlackListFilter = function (_a) {
    var _b = _a.blacklist, blacklist = _b === void 0 ? [] : _b;
    return function (_a) {
        var resourceName = _a.name;
        return !__spread(DEFAULT_REQUEST_BLACKLIST, blacklist).find(function (origin) { return resourceName.includes(origin); });
    };
};
export { getBlackListFilter as default };
