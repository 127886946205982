import { __extends, __read, __spread } from "tslib";
import { CallbackProvider } from '../../../common';
import { isPerformanceObserverSupported } from '../../../common/utils/performance-utils';
import { sendMetricTimingCallback, getPage } from '../utils';
import getBlackListFilter from './blacklist';
import normalisePerformanceResourceTiming from './normalise';
/**
 * Manages sending timings under the broad banner of 'resources'
 *
 * This applies to anything that is accesible in performance.getEntriesByType('resource')
 * and includes.
 *
 * 1. AJAX (Fetch, XHR etc)
 * 2. Assets
 * 3. Other network requests instigated from the DOM.
 */
var ResourceMetricsPlugin = /** @class */ (function (_super) {
    __extends(ResourceMetricsPlugin, _super);
    function ResourceMetricsPlugin(_a) {
        var _b = _a.callbacks, callbacks = _b === void 0 ? [] : _b, _c = _a.blacklist, blacklist = _c === void 0 ? [] : _c;
        var _this = _super.call(this, { callbacks: callbacks }) || this;
        _this.pageTracker = null;
        _this.performance = window.performance;
        _this.timings = [];
        _this.filterEntryList = function (entryList, blacklist) {
            var blackListFilter = getBlackListFilter({ blacklist: blacklist });
            return Array.prototype.filter.call(entryList, blackListFilter);
        };
        if (!isPerformanceObserverSupported()) {
            console.warn('Metal: Performance Observer not supported, ResourceMetricsPlugin will not work');
            return _this;
        }
        var initialEntryList = _this.performance.getEntriesByType('resource');
        var allowedInitialEntryList = _this.filterEntryList(initialEntryList, blacklist);
        _this.timings = allowedInitialEntryList.map(normalisePerformanceResourceTiming);
        // capture future timings
        _this.observer = new PerformanceObserver(function (entries) {
            var _a;
            var entryList = entries.getEntries();
            // important to not get into a recursive loop of sending
            // metrics on network requests stemming from Metal
            var allowedEntryList = _this.filterEntryList(entryList, blacklist);
            var newEntries = allowedEntryList.map(normalisePerformanceResourceTiming);
            // capture locally
            _this.timings = (_a = _this.timings).concat.apply(_a, __spread(newEntries));
            // send to Metal
            if (newEntries.length) {
                _this.send.apply(_this, __spread(newEntries));
            }
        });
        return _this;
    }
    // TODO: this function can probably be removed // call Metal directly
    ResourceMetricsPlugin.prototype.send = function () {
        var _this = this;
        var ajaxResults = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            ajaxResults[_i] = arguments[_i];
        }
        ajaxResults.forEach(function (_a) {
            var name = _a.name, value = _a.value, type = _a.type;
            var page = getPage(_this.pageTracker, false);
            _this.doCallbacks({
                metric: {
                    name: name,
                    value: value,
                    type: type,
                    page: page,
                },
            });
        });
    };
    /**
     * Runs the Resource Timings plugin.
     *
     * @param client An instance of MetalClient
     */
    ResourceMetricsPlugin.prototype.install = function (client) {
        this.pageTracker = client.pageTracker;
        this.callbacks.push(sendMetricTimingCallback(client));
        this.send.apply(this, __spread(this.timings));
        if (this.observer) {
            this.observer.observe({ entryTypes: ['resource'] });
        }
    };
    /**
     * Destroys the Paint Metrics plugin and its references
     */
    ResourceMetricsPlugin.prototype.destroy = function () {
        if (this.observer) {
            this.observer.disconnect();
        }
    };
    return ResourceMetricsPlugin;
}(CallbackProvider));
export { ResourceMetricsPlugin as default };
