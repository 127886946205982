var _a, _b, _c;
import Bowser from 'bowser';
var BROWSER_MAP = Bowser.BROWSER_MAP, OS_MAP = Bowser.OS_MAP, PLATFORMS_MAP = Bowser.PLATFORMS_MAP;
// Whitelists loosely against constant values from 'bowser' ~ 2.5.2
// https://github.com/lancedikson/bowser/blob/master/src/constants.js
var browser = (_a = {},
    _a[BROWSER_MAP.chrome] = 'chrome',
    _a[BROWSER_MAP.chromium] = 'chromium',
    _a[BROWSER_MAP.safari] = 'safari',
    _a[BROWSER_MAP.firefox] = 'firefox',
    _a[BROWSER_MAP.edge] = 'edge',
    _a[BROWSER_MAP.ie] = 'ie',
    _a);
var platform = (_b = {},
    _b[PLATFORMS_MAP.desktop] = 'desktop',
    _b[PLATFORMS_MAP.mobile] = 'mobile',
    _b);
var os = (_c = {},
    _c[OS_MAP.MacOS] = 'macos',
    _c[OS_MAP.Linux] = 'linux',
    _c[OS_MAP.Windows] = 'windows',
    _c[OS_MAP.iOS] = 'ios',
    _c[OS_MAP.Android] = 'android',
    _c);
var whitelists = {
    browser: browser,
    os: os,
    platform: platform,
};
export default whitelists;
