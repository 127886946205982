import { __assign } from "tslib";
import { isPerformanceObserverSupported } from '../../common/utils/performance-utils';
/**
 * Will pull any performance marks or measures that the user specifies
 * and send them to SFX based on a user defined mapping
 */
var PerformanceMarkPlugin = /** @class */ (function () {
    function PerformanceMarkPlugin(userDefinedAllowlist) {
        var _this = this;
        this.allowlist = {};
        if (!isPerformanceObserverSupported()) {
            console.warn('Metal: Performance Observer not supported, PerformanceMarkPlugin will not work');
            return;
        }
        Object.keys(userDefinedAllowlist).forEach(function (metricName) {
            var markItem = userDefinedAllowlist[metricName];
            if (!metricName) {
                throw new Error('Metal: Unsupported metric type supplied to PerformanceMarkPlugin');
            }
            _this.allowlist[markItem.name] = __assign(__assign({}, markItem), { name: metricName });
        });
        this.observer = new PerformanceObserver(this.getMarksCallback.bind(this));
        this.observer.observe({ entryTypes: ['mark', 'measure'] });
    }
    PerformanceMarkPlugin.prototype.getMarksCallback = function (list) {
        var _this = this;
        list.getEntries().forEach(function (entry) {
            if (entry.name in _this.allowlist) {
                var markPayload = _this.allowlist[entry.name];
                var value = entry.duration ? entry.duration : entry.startTime;
                _this.client.metric.submit(__assign(__assign({}, markPayload), { value: value }));
            }
        });
    };
    PerformanceMarkPlugin.prototype.install = function (client) {
        this.client = client;
    };
    PerformanceMarkPlugin.prototype.destroy = function () {
        if (this.observer) {
            this.observer.disconnect();
        }
    };
    return PerformanceMarkPlugin;
}());
export default PerformanceMarkPlugin;
