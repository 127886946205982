import { __extends } from "tslib";
import Hookable from '../../common/hookable';
import { CoreType } from '..';
/**
 * Used from instances of MetalClient to submit errors.
 * - Sends a metric based on the scope of error
 * - Can/Will also be responsible for RavenJS in future.
 *
 * @method submit
 *
 */
var ErrorModel = /** @class */ (function (_super) {
    __extends(ErrorModel, _super);
    function ErrorModel(_a) {
        var hook = _a.hook;
        return _super.call(this, { hook: hook }) || this;
    }
    ErrorModel.prototype.submit = function (error) {
        this.hook({
            type: CoreType.ERROR,
            data: error,
        });
    };
    return ErrorModel;
}(Hookable));
export default ErrorModel;
export * from './types';
