import { __assign, __awaiter, __extends, __generator, __read } from "tslib";
import noop from 'lodash/noop';
import { CallbackProvider } from '../../../common';
import { PerformanceMetricName } from '../../../catalog';
import { TIMEOUT_THRESHOLD } from '../constants';
import { sendMetricTimingCallback, getPage } from '../utils';
import { getPaintTimingsWithPerformanceObserverAsync, getPaintTimingsAsync, getFirstNavTiming, } from './helpers';
var config = { childList: true, subtree: true };
/**
 * High level class instance used to get a best estimate for
 * First Meaningful Paint. By watching the root of the react tree for mutations
 * we can get a fairly good estimate as to when this occurs based on the delta of new nodes.
 */
var PaintMetricsPlugin = /** @class */ (function (_super) {
    __extends(PaintMetricsPlugin, _super);
    /* TODO
      if rootId is not specified, PaintMetrics will still attempt to observe,
      and do nothing presumably - handle this better.
    */
    function PaintMetricsPlugin(_a) {
        var _b;
        var _c = _a.rootId, rootId = _c === void 0 ? 'root' : _c, _d = _a.callbacks, callbacks = _d === void 0 ? [] : _d, _e = _a.collectors, collectors = _e === void 0 ? {} : _e, _f = _a.histograms, histograms = _f === void 0 ? {} : _f;
        var _this = _super.call(this, { callbacks: callbacks }) || this;
        _this.paintTimings = [];
        _this.lastPaintCount = 0;
        _this.histograms = {};
        _this.page = '';
        _this.observer = new MutationObserver(_this.getNewNodes.bind(_this));
        _this.observer.observe(document.getElementById(rootId), config);
        if ('PerformanceObserver' in window) {
            getPaintTimingsWithPerformanceObserverAsync().catch(noop);
        }
        _this.collectors = __assign((_b = {}, _b[PerformanceMetricName.FIRST_MEANINGFUL_PAINT] = _this.getFirstMeaningfulPaintAsync.bind(_this), _b), collectors);
        _this.histograms = histograms;
        // remove observer after TIMEOUT_THRESHOLD
        setTimeout(function () { return _this.observer.disconnect(); }, TIMEOUT_THRESHOLD);
        return _this;
    }
    /**
     * private function for
     * new node callback
     *
     * @returns {PaintTiming}
     */
    PaintMetricsPlugin.prototype.createPaintTimingObject = function () {
        var domSize = document.body.getElementsByTagName('*').length;
        var domCountDelta = domSize - this.lastPaintCount;
        this.lastPaintCount = domSize;
        return { time: performance.now(), domSize: domSize, domCountDelta: domCountDelta };
    };
    /**
     * private function for
     * new node callback
     *
     * @returns {void}
     */
    PaintMetricsPlugin.prototype.getNewNodes = function () {
        this.paintTimings.push(this.createPaintTimingObject());
    };
    /**
     * Used internall to derive the class's FMP output.
     *
     * @param FCPtiming First Contentful Paint timing
     * @returns {number} The FMP
     */
    PaintMetricsPlugin.prototype.getFirstMeaningfulPaint = function (FCPtiming) {
        var firstNavTiming = getFirstNavTiming();
        // omit any timing pre FCP and post first nav
        var usefulTimings = this.paintTimings.filter(function (_a) {
            var time = _a.time;
            return time > FCPtiming && time < firstNavTiming;
        });
        // if there are no timings; FCP === FMP so just return FCP
        if (!usefulTimings.length) {
            return FCPtiming;
        }
        // else we want the _biggest_ layout change that has occurred since FCP
        var _a = __read(usefulTimings.sort(function (_a, _b) {
            var b = _a.domCountDelta;
            var a = _b.domCountDelta;
            return a - b;
        }), 1), time = _a[0].time;
        return time;
    };
    PaintMetricsPlugin.prototype.getFirstMeaningfulPaintAsync = function (FCPtiming) {
        var _this = this;
        var DEFER_CALLBACK = TIMEOUT_THRESHOLD - performance.now();
        return new Promise(function (resolve) {
            setTimeout(function () { return resolve(_this.getFirstMeaningfulPaint(FCPtiming)); }, DEFER_CALLBACK);
        });
    };
    /**
     * Runs the Paint Metrics plugin.
     *
     * @param client An instance of MetalClient
     */
    PaintMetricsPlugin.prototype.install = function (client) {
        this.page = getPage(client.pageTracker, true);
        this.callbacks.push(sendMetricTimingCallback(client));
        this.getTimings();
    };
    /**
     * Destroys the Paint Metrics plugin and its references
     */
    PaintMetricsPlugin.prototype.destroy = function () {
        this.observer.disconnect();
    };
    PaintMetricsPlugin.prototype.handleCallbacks = function (_a) {
        var name = _a.name, value = _a.value;
        this.doCallbacks({
            metric: { name: name, value: value, page: this.page },
            histograms: this.histograms,
            shouldCheckActiveTab: true,
        });
    };
    /**
     * Get the paint timings, and runs the internal callbacks to expose the results to its subscribers.
     */
    PaintMetricsPlugin.prototype.getTimings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var paints, fcp, name_1, value, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getPaintTimingsAsync()];
                    case 1:
                        paints = _a.sent();
                        paints.forEach(this.handleCallbacks.bind(this));
                        fcp = paints.find(function (_a) {
                            var name = _a.name;
                            return name === PerformanceMetricName.FIRST_CONTENTFUL_PAINT;
                        }).value;
                        name_1 = PerformanceMetricName.FIRST_MEANINGFUL_PAINT;
                        return [4 /*yield*/, this.collectors[name_1](fcp)];
                    case 2:
                        value = _a.sent();
                        this.handleCallbacks({ name: name_1, value: value });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        // TODO: probably give a better annotated error.
                        console.warn('PaintMetricsPlugin: Unable to get Paint Timings');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return PaintMetricsPlugin;
}(CallbackProvider));
export { PaintMetricsPlugin as default };
