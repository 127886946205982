import Bowser from 'bowser';
import whitelists from './constants';
function getUserAgentInfo(_a) {
    var _b = (_a === void 0 ? {} : _a).userAgentString, userAgentString = _b === void 0 ? window.navigator.userAgent : _b;
    var rawBrowserInfo = Bowser.getParser(userAgentString);
    var os = whitelists.os[rawBrowserInfo.getOSName()] || 'other';
    var platform = whitelists.platform[rawBrowserInfo.getPlatformType()] || 'other';
    var browser = whitelists.browser[rawBrowserInfo.getBrowserName()] || 'other';
    return {
        os: os,
        platform: platform,
        browser: browser,
    };
}
export { getUserAgentInfo as default };
