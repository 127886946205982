import { __assign } from "tslib";
import { CoreType } from '../../core';
/**
 * ErrorsPlugin automatically hooks into
 * a call to MetalClient.error.submit() and counts it as metric error count
 */
var ErrorsPlugin = /** @class */ (function () {
    function ErrorsPlugin() {
    }
    ErrorsPlugin.prototype.install = function (client) {
        // Listen to error events from MetalClient
        client.addEventHook(CoreType.ERROR, function (_a) {
            var page = _a.page, component = _a.component, name = _a.name;
            client.metric.submit(__assign({ name: name, value: 1, page: page }, (component ? { component: component } : {})));
        });
    };
    return ErrorsPlugin;
}());
export default ErrorsPlugin;
