/**
 * Parts of the MetalCore
 */
export var CoreType;
(function (CoreType) {
    CoreType["METRIC"] = "metric";
    CoreType["ERROR"] = "error";
})(CoreType || (CoreType = {}));
export * from './error';
export * from './metric';
