import { CoreType } from '../core';
import { isType, objectValues } from '../common/utils/object-utils';
import { envTypes } from './constants';
export var validateProductInfo = function (productInfo) {
    if (!productInfo.metalId) {
        throw new Error('Missing metalId');
    }
    if (!productInfo) {
        throw new Error('Missing productInfo');
    }
    if (!productInfo.env) {
        throw new Error('Missing productInfo.env');
    }
    if (!productInfo.version) {
        throw new Error('Missing productInfo.version');
    }
    if (!isType(envTypes, productInfo.env)) {
        throw new Error("Invalid productInfo.env '" + productInfo.env + "', must be an envType: [" + objectValues(envTypes) + "]");
    }
};
export var getDefaultHooks = function () {
    var _a;
    return (_a = {},
        _a[CoreType.METRIC] = [],
        _a[CoreType.ERROR] = [],
        _a);
};
