var defaultGetPageFn = function () { return ''; };
var PageTracker = /** @class */ (function () {
    function PageTracker(getPage) {
        if (getPage === void 0) { getPage = defaultGetPageFn; }
        this.initialPage = '';
        this.initialPage = getPage();
        this.getPage = getPage;
    }
    Object.defineProperty(PageTracker.prototype, "page", {
        get: function () {
            if (this.getPage) {
                var page = this.getPage();
                if (!this.initialPage) {
                    this.initialPage = page;
                }
                return page;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    return PageTracker;
}());
export default PageTracker;
