import {
  AdvancedCobranding,
  isAdvancedCobranding,
  isSimpleCobranding,
  SimpleCobranding,
} from '../../utilities/cobranding/cobranding';
import { CobrandingComponents } from './shared-cobranding';

import DefaultCobranding from './DefaultCobranding';
import BitbucketCobranding from './bitbucket/BitbucketCobranding';
import CompassCobranding from './compass/CompassCobranding';
import ConfluenceCobranding from './confluence/ConfluenceCobranding';
import JiraCobranding from './jira/JiraCobranding';
import OpsgenieCobranding from './opsgenie/OpsgenieCobranding';
import StatuspageCobranding from './statuspage/StatuspageCobranding';
import TrelloCobranding from './trello/TrelloCobranding';
import WacCobranding from './wac/WacCobranding';
import AtlasCobranding from './atlas/AtlasCobranding';
import WacSignupOTPExperimentJira from './wac/WacSignupOTPExperimentJira';
import WacSignupOTPExperimentConfluence from './wac/WacSignupOTPExperimentConfluence';
import LoomCobranding from './loom/LoomCobranding';
import JiraAlignCobranding from './jira-align/JiraAlignCobranding';
import AdminExperimentForJira from '../../advanced-cobranding/admin/atlas-75650/jira/AdminExperimentForJira';
import AdminExperimentForConfluence from '../../advanced-cobranding/admin/atlas-75650/confluence/AdminExperimentForConfluence';

const cobrandingMap: { [K in SimpleCobranding | AdvancedCobranding]: CobrandingComponents } = {
  [SimpleCobranding.NO_COBRANDING]: DefaultCobranding,
  [SimpleCobranding.BITBUCKET]: BitbucketCobranding,
  [SimpleCobranding.COMPASS]: CompassCobranding,
  [SimpleCobranding.CONFLUENCE]: ConfluenceCobranding,
  [SimpleCobranding.JIRA]: JiraCobranding,
  [SimpleCobranding.LOOM]: LoomCobranding,
  [SimpleCobranding.JIRA_ALIGN]: JiraAlignCobranding,
  [SimpleCobranding.OPSGENIE]: OpsgenieCobranding,
  [SimpleCobranding.STATUSPAGE]: StatuspageCobranding,
  [SimpleCobranding.TRELLO]: TrelloCobranding,
  [SimpleCobranding.WAC]: WacCobranding,
  [SimpleCobranding.ATLAS]: AtlasCobranding,
  [SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_JIRA]: WacSignupOTPExperimentJira,
  [SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_CONFLUENCE]: WacSignupOTPExperimentConfluence,
  [AdvancedCobranding.JIRA_ATLAS_75650]: AdminExperimentForJira,
  [AdvancedCobranding.CONFLUENCE_ATLAS_75650]: AdminExperimentForConfluence,
  [AdvancedCobranding.DEMO]: DefaultCobranding,
  [AdvancedCobranding.ATLAS_66195]: DefaultCobranding,
  [AdvancedCobranding.CFIND_2409]: DefaultCobranding,
  [AdvancedCobranding.ACCOUNT_LINKING]: DefaultCobranding,
  [AdvancedCobranding.ENTRY_AUTH]: DefaultCobranding,
  default: DefaultCobranding,
};

export const getCobrandedComponents = (
  cobranding: SimpleCobranding | AdvancedCobranding
): CobrandingComponents => {
  if (isSimpleCobranding(cobranding) || isAdvancedCobranding(cobranding)) {
    return cobrandingMap[cobranding];
  } else {
    console.warn(
      `[COBRANDING] ${cobranding} is globally configured for advanced-cobranding but corresponding configuration for ${window.location.pathname} was not provided. Loading SimpleCobranding.NO_BRANDING as fallback.`
    );
    return cobrandingMap[SimpleCobranding.NO_COBRANDING];
  }
};
