import { __assign } from "tslib";
// expects a kebab case formatted name
// eg. first-meaningful-paint => fcp
export var nameToFirstLetters = function (name) {
    return name
        .split('-')
        .map(function (word) { return word.slice(0, 1); })
        .map(function (char) { return char.toLowerCase(); })
        .join('');
};
export var sendMetricTimingCallback = function (client) { return function (_a) {
    var metric = _a.metric, _b = _a.histograms, histograms = _b === void 0 ? {} : _b, _c = _a.shouldCheckActiveTab, shouldCheckActiveTab = _c === void 0 ? false : _c;
    if (shouldCheckActiveTab) {
        var wasPreviouslyHidden = client.pageVisibility.wasPreviouslyHidden;
        if (wasPreviouslyHidden) {
            return;
        }
    }
    client.metric.submit(metric);
    if (histograms[metric.name]) {
        client.metric.submit(__assign(__assign({}, metric), { histogramBuckets: histograms[metric.name] }));
    }
}; };
export var getPage = function (pageTracker, isInitial) {
    if (pageTracker) {
        if (isInitial) {
            return pageTracker.initialPage;
        }
        return pageTracker.page;
    }
    return '';
};
