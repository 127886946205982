import { createContext, useContext } from 'react';
import { State as CobrandingState } from '../../reducers/cobranding-reducer';

import { ApplicationData, lookupApplication } from '../applications';
import { AidFrontendConfigCollection } from '../feature-flags/dynamic-config';
import { AidFrontendDynamicConfig } from '../../providers/DynamicConfigs';

export enum SimpleCobranding {
  NO_COBRANDING = 'no-cobranding',
  BITBUCKET = 'bitbucket',
  COMPASS = 'compass',
  CONFLUENCE = 'confluence',
  JIRA = 'jira',
  OPSGENIE = 'opsgenie',
  STATUSPAGE = 'statuspage',
  TRELLO = 'trello',
  WAC = 'wac',
  ATLAS = 'atlas',
  LOOM = 'loom',
  JIRA_ALIGN = 'jira-align',
  WAC_SIGNUP_OTP_EXPERIMENT_JIRA = 'wac-signup-otp-experiment-jira',
  WAC_SIGNUP_OTP_EXPERIMENT_CONFLUENCE = 'wac-signup-otp-experiment-confluence',
}

export enum AdvancedCobranding {
  DEMO = 'demo',
  ATLAS_66195 = 'atlas-66195',
  CFIND_2409 = 'cfind-2409',
  JIRA_ATLAS_75650 = 'jira-atlas-75650',
  CONFLUENCE_ATLAS_75650 = 'confluence-atlas-75650',
  LOOM = 'default',
  ACCOUNT_LINKING = 'account-linking',
  ENTRY_AUTH = 'entry-auth',
}

export const isSimpleCobranding = (value: string): value is SimpleCobranding =>
  Object.values<string>(SimpleCobranding).includes(value);

export const isAdvancedCobranding = (value: string): value is AdvancedCobranding =>
  Object.values<string>(AdvancedCobranding).includes(value);

const getDisabledApplicationKeys = (dynamicConfigs: AidFrontendConfigCollection) => {
  const disabledApplicationKeys = dynamicConfigs.getStringList(
    AidFrontendDynamicConfig.DISABLED_COBRANDING_APPLICATION_KEYS
  );

  if ('value' in disabledApplicationKeys) {
    return disabledApplicationKeys.value;
  }

  return [];
};

const getApplicationKey = (applicationData: ApplicationData) => {
  return `${applicationData.application}${
    applicationData.experience ? `--${applicationData.experience}` : ''
  }`;
};

export function getCurrentCobranding(
  cobranding: CobrandingState,
  dynamicConfigs: AidFrontendConfigCollection
): SimpleCobranding | AdvancedCobranding {
  const applicationData = lookupApplication(cobranding);

  if (applicationData) {
    const disabledApplicationKeys = getDisabledApplicationKeys(dynamicConfigs);
    const applicationKey = getApplicationKey(applicationData);

    if (disabledApplicationKeys.includes(applicationKey)) {
      console.log(`Cobranding disabled for application: ${applicationKey}`);
      return SimpleCobranding.NO_COBRANDING;
    }

    switch (applicationData.application) {
      case 'admin':
        switch (applicationData.experience) {
          case 'jira-atlas-75650':
            return AdvancedCobranding.JIRA_ATLAS_75650;
          case 'confluence-atlas-75650':
            return AdvancedCobranding.CONFLUENCE_ATLAS_75650;
          default:
            return SimpleCobranding.NO_COBRANDING;
        }
      case 'atlas':
        return SimpleCobranding.ATLAS;
      case 'bitbucket':
        return SimpleCobranding.BITBUCKET;
      case 'community': {
        if (applicationData.experience === 'account-linking') {
          return AdvancedCobranding.ACCOUNT_LINKING;
        }
        if (applicationData.experience === 'entry-auth') {
          return AdvancedCobranding.ENTRY_AUTH;
        }
        break;
      }
      case 'compass':
        return SimpleCobranding.COMPASS;
      case 'confluence':
        return SimpleCobranding.CONFLUENCE;
      case 'jira':
        return SimpleCobranding.JIRA;
      case 'loom':
        switch (applicationData.experience) {
          case 'default':
            return AdvancedCobranding.LOOM;
          default:
            return SimpleCobranding.LOOM;
        }
      case 'jira-align':
        return SimpleCobranding.JIRA_ALIGN;
      case 'opsgenie':
        return SimpleCobranding.OPSGENIE;
      case 'statuspage':
        return SimpleCobranding.STATUSPAGE;
      case 'trello':
        return SimpleCobranding.TRELLO;
      case 'wac':
        switch (applicationData.experience) {
          case 'otp-jira':
          case 'atlas-66195':
            return AdvancedCobranding.ATLAS_66195;
          case 'otp-confluence':
          case 'cfind-2409':
            return AdvancedCobranding.CFIND_2409;
          default:
            return SimpleCobranding.WAC;
        }
      case 'pollinator':
        if (applicationData.experience) {
          if (applicationData.experience.startsWith('jira')) {
            return SimpleCobranding.JIRA;
          }
          if (applicationData.experience.startsWith('confluence')) {
            return SimpleCobranding.CONFLUENCE;
          }
          if (applicationData.experience === 'demo') {
            return AdvancedCobranding.DEMO;
          }
        }
        return SimpleCobranding.NO_COBRANDING;
    }
  }

  return SimpleCobranding.NO_COBRANDING;
}

export interface CobrandingContext {
  cobranding: SimpleCobranding | AdvancedCobranding;
  isMobileApp: boolean;
}

const CobrandingContext = createContext<CobrandingContext>({
  cobranding: SimpleCobranding.NO_COBRANDING,
  isMobileApp: false,
});

export const CobrandingProvider = CobrandingContext.Provider;
export const CobrandingConsumer = CobrandingContext.Consumer;

export const useCobranding = () => useContext<CobrandingContext>(CobrandingContext);
