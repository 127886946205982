import { portalClassName, portalParentClassName, portalParentSelector } from '../constants';

/**
 * Creates a new portal container element with provided z-index and class name 'atlaskit-portal',
 * it is not be attached to any DOM node at this stage.
 * @param {number | string} zIndex - the z-index value of the newly created portal container element
 * @return {number} - The newly created container element
 */
export var createContainer = function createContainer(zIndex) {
  var container = document.createElement('div');
  container.className = portalClassName;
  container.style.zIndex = "".concat(zIndex);
  return container;
};

/**
 * Returns document body element
 * @return {number} - The document body element
 */
var getBody = function getBody() {
  return document.body;
};

/**
 * Returns portal parent container. If no container exists already then it creates a new container with class name 'atlaskit-portal-container'
 * @return {Element} - The portal parent container div element
 */
var getPortalParent = function getPortalParent() {
  var parentElement = document.querySelector(portalParentSelector);
  if (!parentElement) {
    var _getBody;
    var parent = document.createElement('div');
    parent.className = portalParentClassName;
    // we are setting display to flex because we want each portal to create a new stacking context
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
    parent.style.display = 'flex';
    (_getBody = getBody()) === null || _getBody === void 0 || _getBody.appendChild(parent);
    return parent;
  }
  return parentElement;
};

/**
 * Removes portal container from portal parent container
 *  @param {HTMLDivElement | undefined} container - portal container to be removed from portal parent container
 */
export var removePortalContainer = function removePortalContainer(container) {
  getPortalParent().removeChild(container);
};

/**
 * Appends portal container to portal parent container if it hasn't already been done
 *  @param {HTMLDivElement | undefined} container - portal container to be added to portal parent container
 */
export var appendPortalContainerIfNotAppended = function appendPortalContainerIfNotAppended(container) {
  if (!container.parentElement) {
    getPortalParent().appendChild(container);
  }
};
export var isDocumentDefined = function isDocumentDefined() {
  return document !== undefined;
};
export var createAtlaskitPortal = function createAtlaskitPortal(zIndex) {
  //atlaskit-portal div
  if (isDocumentDefined()) {
    var atlaskitportal = document.createElement('div');
    atlaskitportal.className = portalClassName;
    atlaskitportal.style.zIndex = "".concat(zIndex);
    return atlaskitportal;
  }
};
export var createPortalParent = function createPortalParent() {
  //atlaskit-portal-container div
  if (isDocumentDefined()) {
    var parentElement = document.querySelector(portalParentSelector);
    if (!parentElement) {
      var _getBody2;
      var parent = document.createElement('div');
      parent.className = portalParentClassName;
      parent.style.display = 'flex';
      (_getBody2 = getBody()) === null || _getBody2 === void 0 || _getBody2.appendChild(parent);
      return parent;
    }
    return parentElement;
  }
};