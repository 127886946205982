import { getDocumentHiddenValues } from './helpers';
var PageVisibility = /** @class */ (function () {
    function PageVisibility() {
        var _this = this;
        this.callbacks = new Map();
        this.lastHiddenAt = 0;
        this.bindEventListeners = function () {
            document.addEventListener(_this.visibilityChange, _this.handleVisibilityChange, false);
        };
        this.handleVisibilityChange = function () {
            var isHidden = document[_this.hidden];
            _this.documentHiddenInfo.isHidden = isHidden;
            if (isHidden) {
                _this.lastHiddenAt = performance.now();
            }
            if (!_this.wasPreviouslyHidden) {
                _this.wasPreviouslyHidden = isHidden;
            }
            _this.callbacks.forEach(function (callbackFn) { return callbackFn(isHidden); });
        };
        this.documentHiddenInfo = getDocumentHiddenValues();
        this.wasPreviouslyHidden = this.documentHiddenInfo.isHidden;
        if (typeof document.addEventListener !== 'undefined' && this.hidden !== undefined) {
            this.bindEventListeners();
        }
    }
    PageVisibility.prototype.addCallback = function (name, fn) {
        if (typeof name !== 'string') {
            throw new Error('Invalid name, must be string');
        }
        if (typeof fn !== 'function') {
            throw new Error('Invalid callback, must be function');
        }
        this.callbacks.set(name, fn);
    };
    PageVisibility.prototype.removeCallback = function (name) {
        if (this.callbacks.has(name)) {
            this.callbacks.delete(name);
        }
    };
    Object.defineProperty(PageVisibility.prototype, "isHidden", {
        get: function () {
            return this.documentHiddenInfo.isHidden;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PageVisibility.prototype, "hidden", {
        get: function () {
            return this.documentHiddenInfo.hidden;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PageVisibility.prototype, "visibilityChange", {
        get: function () {
            return this.documentHiddenInfo.visibilityChange;
        },
        enumerable: false,
        configurable: true
    });
    return PageVisibility;
}());
export default PageVisibility;
