import { __assign } from "tslib";
var Queue = /** @class */ (function () {
    function Queue(settings, callback) {
        var _this = this;
        this.buffer = [];
        this.settings = settings;
        this.callback = callback;
        this.intervalHandle = setInterval(this.flush.bind(this), this.settings.flushInterval);
        window.addEventListener('beforeunload', function () {
            if (_this.intervalHandle) {
                clearInterval(_this.intervalHandle);
            }
        });
    }
    Queue.prototype.flush = function () {
        this.callback(this.buffer, this.settings.bufferType);
        // TODO: there is potential data loss here if the send call fails
        // FIX ME Probably handle in sender tho.
        this.buffer = [];
    };
    Queue.prototype.enqueue = function (payload) {
        this.buffer.push(payload);
        var maxBufferSize = this.settings.maxBufferSize;
        if (maxBufferSize > 0 && this.buffer.length >= maxBufferSize) {
            this.flush();
        }
    };
    Queue.prototype.updateSettings = function (newSettings) {
        this.settings = __assign(__assign({}, this.settings), newSettings);
        if (newSettings.flushInterval) {
            clearInterval(this.intervalHandle);
            this.intervalHandle = setInterval(this.flush.bind(this), newSettings.flushInterval);
        }
    };
    return Queue;
}());
export default Queue;
