var errors = {
    metalhead: {
        notfound: "Metal: For TTI & FID metrics,\n      you should include metal-head.min.js within <head>\n      to setup observers and timings as early as possible.",
    },
    metrics: {
        browser: {
            rootId: "Metal: Browser Metrics:\n        rootId must be supplied to instantiate this plugin",
        },
    },
    metalClient: {
        metalId: "Metal: Deprecation warning:\n      Metal will require a metalId to determine config in future versions.\n      Please update your client's config.",
    },
    plugins: {
        paint: {
            unsupported: 'Metal: Performance Painting Timings: not supported by this browser',
        },
        interactive: {
            longtask: 'Metal: Long task not supported',
        },
    },
};
export { errors };
